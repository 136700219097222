import $ from 'jquery';
import moment from 'moment';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { convertToSlug, getPru15API } from "../js/Helper";
import ContentLoader from "react-content-loader"


function NewsDetail(props) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [news, setItems] = useState({});
    const [newsList, setNewsList] = useState([]);
    const { id } = useParams();

    let curStorage = localStorage.getItem("news_read")
    var regex = (/(https?:\/\/[^ ]*\.(?:gif|png|jpg|jpeg))/i);

    function getImageFromStr(str) {
        if (new RegExp(regex).test(str)) {
            const imgurl = regex.exec(str)[1];

            if (imgurl)
                return imgurl;
        }
    }



    function getPru15() {
        let news = localStorage.getItem('news')


        if (news) {
            news = JSON.parse(news)

            console.log(news)
            setItems(news[id])
            setNewsList(news)
            setIsLoaded(true)
        } else {
            getPru15API().then((rss) => {
                localStorage.setItem('news', JSON.stringify(rss.items))
                localStorage.setItem('news_expiry', (new Date()).getTime() + (1000 * 360))
                getPru15()
                setIsLoaded(true)
            })
        }
    }

    function newsRead(title) {
        const readNews = convertToSlug(title)
        if (curStorage) {
            curStorage = curStorage.split(",")
        } else {
            curStorage = []
        }

        if (!curStorage.includes(readNews)) {
            curStorage.push(readNews)
            localStorage.setItem("news_read", curStorage.toString());
        }
    }

    useEffect(() => {
        $(document).scrollTop(0)
        getPru15()
        document.title = "Berita PRU15"; 
    }, [id])

    useEffect(() => {
        document.title = "Berita PRU15"; 
    }, []);  

    return (
        <div>

            <div class="red-separator"></div>

            <div class="hot-news-wrapper">
                <div class="row ">
                    <div class="col-md-8">
                        <div class="card mb-15 news-item news-single">
                            <div class="row g-0">

                                <div class="col-md-12">
                                    {
                                        isLoaded ? (
                                            <div class="card-body">
                                                <h5 class="card-title" dangerouslySetInnerHTML={{ __html: news.title }}></h5>
                                                <p class="card-text"><small class="text-muted">{news.author} . {moment(news.created).format('MMMM DD, YYYY')}</small></p>

                                                <p class="card-text" dangerouslySetInnerHTML={{ __html: news.content }}></p>
                                            </div>
                                        ) : (
                                            <ContentLoader
                                                speed={2}
                                                width={800}
                                                height={700}
                                                viewBox="0 0 800 700"
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#ecebeb"
                                            >
                                                <rect x="1" y="8" rx="3" ry="3" width="387" height="21" />
                                                <rect x="2" y="41" rx="3" ry="3" width="59" height="20" />
                                                <rect x="71" y="40" rx="3" ry="3" width="127" height="20" />
                                                <rect x="2" y="73" rx="3" ry="3" width="800" height="417" />
                                                <rect x="-19" y="508" rx="3" ry="3" width="461" height="21" />
                                                <rect x="-11" y="543" rx="3" ry="3" width="650" height="21" />
                                                <rect x="-9" y="577" rx="3" ry="3" width="650" height="21" />
                                                <rect x="-9" y="612" rx="3" ry="3" width="650" height="21" />
                                            </ContentLoader>
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-4">
                        <div class="popular-news-head">
                            <div class="popular-news-title">
                                Paling Popular
                            </div>
                            <div class="popular-news-title more-popular">
                                Lagi
                            </div>


                        </div>

                        <div class=" most-popular-wrapper">
                            {
                                isLoaded ? (
                                    newsList.map((item, idx) => {
                                        let existsInStorage = false
                                        if(curStorage)
                                            existsInStorage = curStorage.includes(convertToSlug(item.title))
                                        return (
                                            <div class="card mb-3 news-item">
                                                <div class="row g-0">
                                                    <div class="col-md-4">
                                                        <img src={getImageFromStr(item.description)} class="img-fluid rounded-start" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="card-body">
                                                            <h5 class="card-title">
                                                                <Link to={`/berita/${convertToSlug(item.title)}/${idx}`} className="nav-link px-0 link-secondary" style={existsInStorage ? {color: 'blue'} : {color: 'black'}} onClick={() => { newsRead(item.title); }} dangerouslySetInnerHTML={{ __html: item.title }}>
                                                                </Link>
                                                            </h5>
                                                            <p class="card-text"><small class="text-muted">{item.author} . {moment(item.created).format('MMMM DD, YYYY')}</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })

                                ) : (
                                    Array.from({ length: 10 }).map(() => {
                                        return (
                                            <ContentLoader
                                                speed={2}
                                                width={450}
                                                height={130}
                                                viewBox="0 0 450 130"
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#ecebeb"
                                            >
                                                <rect x="193" y="7" rx="3" ry="3" width="210" height="20" />
                                                <rect x="195" y="87" rx="3" ry="3" width="210" height="14" />
                                                <rect x="193" y="37" rx="3" ry="3" width="154" height="20" />
                                                <rect x="6" y="6" rx="3" ry="3" width="164" height="95" />
                                            </ContentLoader>
                                        )
                                    })
                                )

                            }
                        </div>

                    </div>

                </div>
            </div>

        </div >
    );
}

export default NewsDetail;
