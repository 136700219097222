import React, { useEffect, useState } from "react";
import TopTicker from "../components/TopTicker";

function WidgetTicker() {
    return (
        <div>
            <TopTicker />
        </div>
    );
}
export default WidgetTicker;