import $ from 'jquery';
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { useParams } from "react-router-dom";
import svgPanZoom from 'svg-pan-zoom';
import { ReactComponent as MalaysiaMap } from "../components/map/malaysia-map.svg";
import Infographic from "../components/Infographic";
import StateMiddleMenu from "../components/StateMiddleMenu";

function Results() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [partyTable, setPartyTable] = useState([]);
  const [seatParliamentTable, setSeatParliamentTable] = useState([]);
  const [seatDunTable, setSeatDunTable] = useState([]);
  const [seatType, setSeatType] = useState('PARLIMEN');
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 767;

  let { election = 'pru14',state } = useParams();
  let totalWin = 0;
  let totaldBnWin = 0;
  let totaldPhWin = 0;
  let totaldDllWin = 0;
  let totalpBnWin = 0;
  let totalpPhWin = 0;
  let totalpGpsWin = 0;
  let totalpDllWin = 0;
  let BnName;
  let PhName;
  let GpsName;
  let DllName; 
  let bgBnColor;
  let bgPhColor;
  let bgGpsColor;
  let bgDllColor;  

  function getPartyParliamentTable() {
    fetch(`${process.env.REACT_APP_API_URL}/api/microsite/overall/tally_parliament?election=${election}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setPartyTable(result.tally);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  function getSeatTable() {
    fetch(`${process.env.REACT_APP_API_URL}/api/microsite/overall/seat_table?election=${election}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setSeatDunTable(result.DUN);
          setSeatParliamentTable(result.Parliament);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  useEffect(() => {

    $(document).on('mousemove', '#map-wrapper .small-box', function (e) {
      let overlay_id;

      if(seatType === 'PARLIMEN')
        overlay_id = 'P_' + (e.target).parentNode.id.toUpperCase();
      else
        overlay_id = 'D_' + (e.target).parentNode.id.toUpperCase();
      
      if((e.target).parentNode.id === 'SARAWAK' || (e.target).parentNode.id === 'SABAH' || (e.target).parentNode.id === 'WPLabuan') {
        $('#' + overlay_id).css(
          { top: e.clientY - 70, left: e.clientX - 425, }
        );
      }else{
        $('#' + overlay_id).css(
          { top: e.clientY - 70, left: e.clientX + 30, }
        );
      }
    })

    $(document).on('mouseleave', '#map-wrapper .small-box', function (e) {
      $('.map-hover-info-wrapper').hide();
    })    

    $(document).on('mouseover', '#map-wrapper .small-box', function (e) {
      let overlay_id;
      $('.map-hover-info-wrapper').hide();
      
      if(seatType === 'PARLIMEN')
        overlay_id = 'P_' + (e.target).parentNode.id.toUpperCase();
      else
        overlay_id = 'D_' + (e.target).parentNode.id.toUpperCase();

      $('#' + overlay_id).show();
    })

    if(seatType === 'PARLIMEN'){
      seatParliamentTable?.map((data, idx) => {
        let index = 'seatparliamenttable_' + idx;
        let state_map = data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','');
        bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
        bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
        bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
        bgDllColor = 'silver';

        const party_list = [
          {id: 'BN', win: data.stats[0].BN ? data.stats[0].BN.win : 0},
          {id: 'PH', win: data.stats[0].PH ? data.stats[0].PH.win : 0},
          {id: 'GPS', win: data.stats[0].GPS ? data.stats[0].GPS.win : 0},
          {id: 'DLL', win: data.stats[0].DLL ? data.stats[0].DLL.win : 0},
        ];

        let winner = party_list.sort((a, b) => (a.win < b.win) ? 1 : -1)[0].id;

        if(winner === 'BN')
          $('#' + state_map).css('fill', bgBnColor);

        if(winner === 'PH')
          $('#' + state_map).css('fill', bgPhColor);

        if(winner === 'GPS')
          $('#' + state_map).css('fill', bgGpsColor);
          
        if(winner === 'DLL')
          $('#' + state_map).css('fill', bgDllColor);
      });
    }else{
      seatDunTable.map((data, idx) => {
        let index = 'seatduntable_' + idx;
        let state_map = data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','');
        bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
        bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
        bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
        bgDllColor = 'silver';
  
        const party_list = [
          {id: 'BN', win: data.stats[0].BN ? data.stats[0].BN.win : 0},
          {id: 'PH', win: data.stats[0].PH ? data.stats[0].PH.win : 0},
          {id: 'GPS', win: data.stats[0].GPS ? data.stats[0].GPS.win : 0},
          {id: 'DLL', win: data.stats[0].DLL ? data.stats[0].DLL.win : 0},
        ];
  
        let winner = party_list.sort((a, b) => (a.win < b.win) ? 1 : -1)[0].id;
  
        if(winner === 'BN')
          $('#' + state_map).css('fill', bgBnColor);
  
        if(winner === 'PH')
          $('#' + state_map).css('fill', bgPhColor);
  
        if(winner === 'GPS')
          $('#' + state_map).css('fill', bgGpsColor);
          
        if(winner === 'DLL')
          $('#' + state_map).css('fill', bgDllColor); 

        $('#WPKUALALUMPUR').css('fill', '#000');
        $('#WPPUTRAJAYA').css('fill', '#000');
        $('#WPLABUAN').css('fill', '#000');
        //$('#SARAWAK').css('fill', '#000');
      }); 
    }   
  }, [seatType])


  useEffect(() => {
    setPartyTable([]);
    setSeatParliamentTable([]);
    setSeatDunTable([]);
    getPartyParliamentTable();
    getSeatTable();

    let zoomMap = svgPanZoom('#svgMalaysia', {
      zoomEnabled: false,
      controlIconsEnabled: false,
      fit: true,
      center: true,
    });

    document.querySelector('.nav-map-zoom-out').addEventListener('click', function () {
      zoomMap.zoomOut();
    })
    document.querySelector('.nav-map-zoom-in').addEventListener('click', function () {
      zoomMap.zoomIn();
    })
    document.querySelector('.nav-map-zoom-reset').addEventListener('click', function () {
      zoomMap.reset();
    })

    document.title = "Keputusan " + election.toUpperCase(); 
  }, [election, state])

  useEffect(() => {
    document.title = "Keputusan " + election.toUpperCase(); 
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <>
      <Row></Row>
      <StateMiddleMenu election={election} />
      <Row></Row>


      <Infographic election={election} state={state} />

      <div class="decision-content-wrapper">
        <div class=" decision-table-content">
          <Table bordered hover>
            <thead>
              <tr>
                <th></th>
                <th style={width < breakpoint ? {textAlign: "left", fontSize: '12px'} : {textAlign: "left"}}>PARTI</th>
                <th style={width < breakpoint ? {textAlign: "center", width: '100px', fontSize: '12px'} : {textAlign: "center"}}>MENANG</th>
                <th style={width < breakpoint ? {textAlign: "center", width: '100px', fontSize: '12px'} : {textAlign: "center"}}>BERTANDING</th>
              </tr>
            </thead>
            <tbody>

              {

                partyTable.map((data, idx) => {
                  totalWin = totalWin + data.win;
                  let index = 'partytable_' + idx;
                  return (
                    <tr key={index}>
                      <td style={width < breakpoint ? {width: '35px', backgroundColor: data.coalition_color} : {width: '150px', backgroundColor: data.coalition_color}}></td>
                      <td style={width < breakpoint ? {textAlign: "left", fontSize: '13px'} : {textAlign: "left"}}>{width < breakpoint ? data.coalition_abbreviation.toUpperCase() : data.coalition_name.toUpperCase()}</td>
                      <td style={width < breakpoint ? {textAlign: "center", fontSize: '14px'} : {textAlign: "center"}}>{data.win}</td>
                      <td style={width < breakpoint ? {textAlign: "center", fontSize: '14px'} : {textAlign: "center"}}>{data.run}</td>
                    </tr>
                  )
                })
              }

              <tr>
                <th colSpan={2} style={width < breakpoint ? {textAlign: "center", fontSize: '12px'} : {textAlign: "center"}}>JUMLAH</th>
                <td style={width < breakpoint ? {textAlign: "center", fontSize: '14px', fontWeight: '800'} : {textAlign: "center", fontWeight: '800'}}>{totalWin}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div class="decision-content-wrapper mt-4">

        <Row>
          <div class="col">
            <div class=" decision-table-content">
              <span class="fw-bold" style={width < breakpoint ? {fontSize: '16px'} : {}}>KERUSI PARLIMEN</span>
              {
                seatParliamentTable.map((data, idx) => {
                  bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
                  bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
                  bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
                  bgDllColor = 'silver';
                  
                  
                  if(election === 'pru15') {
                    BnName = 'BN';
                    PhName = 'PH';
                    GpsName = 'GPS';
                    DllName = 'DLL'; 
                  }else{
                    BnName = 'BN';
                    PhName = 'PKR';
                    GpsName = 'GPS';
                    DllName = 'DLL'; 
                  }
                })
              }
              <Table bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th style={width < breakpoint ? {textAlign: "center", fontSize: '12px', width: '50px'} : {textAlign: "center"}}>{width < breakpoint ? '' : 'BIL. '}KERUSI</th>
                    <th style={width < breakpoint ? {textAlign: "center", fontSize: '12px'} : {textAlign: "center"}}>PARLIMEN</th>
                    <th style={width < breakpoint ? {textAlign: "center", backgroundColor: bgBnColor, color: '#fff', fontSize: '12px', width: '50px'} : {textAlign: "center", backgroundColor: bgBnColor, color: '#fff'}}>{BnName}</th>
                    <th style={width < breakpoint ? {textAlign: "center", backgroundColor: bgPhColor, color: '#fff', fontSize: '12px', width: '50px'} : {textAlign: "center", backgroundColor: bgPhColor, color: '#fff'} }>{PhName}</th>
                    <th style={election === 'pru15' ? width < breakpoint ? {textAlign: "center", display: 'inline', backgroundColor: bgGpsColor, fontSize: '12px', width: '50px'} : {textAlign: "center", display: 'inline', backgroundColor: bgGpsColor} : {display: 'none'}}>{GpsName}</th>
                    <th style={width < breakpoint ? {textAlign: "center", backgroundColor: bgDllColor, fontSize: '12px', width: '50px'} : {textAlign: "center", backgroundColor: bgDllColor}}>{DllName}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    seatParliamentTable.map((data, idx) => {
                      let index = 'seatparliament_' + idx;
                      if(data.stats[0].BN)
                        totalpBnWin = totalpBnWin + data.stats[0].BN.win;
                      if(data.stats[0].PH)
                        totalpPhWin = totalpPhWin + data.stats[0].PH.win;
                      if(data.stats[0].GPS)
                        totalpGpsWin = totalpGpsWin + data.stats[0].GPS.win;  
                      if(data.stats[0].DLL)  
                        totalpDllWin = totalpDllWin + data.stats[0].DLL.win;

                      const party_list = [
                        {id: 'BN', win: data.stats[0].BN ? data.stats[0].BN.win : 0},
                        {id: 'PH', win: data.stats[0].PH ? data.stats[0].PH.win : 0},
                        {id: 'GPS', win: data.stats[0].GPS ? data.stats[0].GPS.win : 0},
                        {id: 'DLL', win: data.stats[0].DLL ? data.stats[0].DLL.win : 0},
                      ];
              
                      let winner = party_list.sort((a, b) => (a.win < b.win) ? 1 : -1)[0].id;
                      console.log(winner);

                      return (
                        <tr key={index}>
                          <td style={width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.stats[0].total_seat}</td>
                          <td style={width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.state_name}</td>
                          <td style={winner === 'BN' ? width < breakpoint ? {textAlign: "center", fontSize: '13px', backgroundColor: bgBnColor, color: '#fff'} : {textAlign: "center", backgroundColor: bgBnColor, color: '#fff'} : width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.stats[0].BN ? data.stats[0].BN.win : 0}</td>
                          <td style={winner === 'PH' ? width < breakpoint ? {textAlign: "center", fontSize: '13px', backgroundColor: bgPhColor, color: '#fff'} : {textAlign: "center", backgroundColor: bgPhColor, color: '#fff'} : width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.stats[0].PH ? data.stats[0].PH.win : 0}</td>
                          <td style={election === 'pru15' && winner === 'GPS' ? width < breakpoint ? {textAlign: "center", display: 'inline', fontSize: '13px', backgroundColor: bgGpsColor, color: '#fff'} : {textAlign: "center", display: 'inline', backgroundColor: bgGpsColor, color: '#fff'} : {display: "none"}}>{data.stats[0].GPS ? data.stats[0].GPS.win : 0}</td>
                          <td style={winner === 'DLL' ? width < breakpoint ? {textAlign: "center", fontSize: '13px', backgroundColor: bgDllColor, color: '#fff'} : {textAlign: "center", backgroundColor: bgDllColor, color: '#fff'} : width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.stats[0].DLL ? data.stats[0].DLL.win : 0}</td>
                        </tr>
                      )
                    })
                  }
                  <tr>
                    <th colSpan={2} style={width < breakpoint ? {textAlign: "center", fontSize: '12px'} : {textAlign: "center"}}>JUMLAH</th>
                    <td style={width < breakpoint ? {textAlign: "center", fontSize: '14px', fontWeight: '800'} : {textAlign: "center", fontWeight: '800'}}>{totalpBnWin}</td>
                    <td style={width < breakpoint ? {textAlign: "center", fontSize: '14px', fontWeight: '800'} : {textAlign: "center", fontWeight: '800'}}>{totalpPhWin}</td>
                    <td style={election === 'pru15' ? width < breakpoint ? {textAlign: "center", fontSize: '14px', fontWeight: '800', display: 'inline'} : {textAlign: "center", fontWeight: '800', display: 'inline'} : {display: 'none'}}>{totalpGpsWin}</td>
                    <td style={width < breakpoint ? {textAlign: "center", fontSize: '14px', fontWeight: '800'} : {textAlign: "center", fontWeight: '800'}}>{totalpDllWin}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div class="col">
            <div class=" decision-table-content">
              <span class="fw-bold text-align-right" style={width < breakpoint ? {fontSize: '16px'} : {}}>KERUSI DEWAN UNDANGAN NEGERI</span>

              <Table bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th style={width < breakpoint ? {textAlign: "center", fontSize: '12px', width: '50px'} : {textAlign: "center"}}>{width < breakpoint ? '' : 'BIL. '}KERUSI</th>
                    <th style={width < breakpoint ? {textAlign: "center", fontSize: '12px'} : {textAlign: "center"}}>DUN</th>
                    <th style={width < breakpoint ? {textAlign: "center", backgroundColor: bgBnColor, color: '#fff', fontSize: '12px', width: '50px'} : {textAlign: "center", backgroundColor: bgBnColor, color: '#fff'}}>{BnName}</th>
                    <th style={width < breakpoint ? {textAlign: "center", backgroundColor: bgPhColor, color: '#fff', fontSize: '12px', width: '50px'} : {textAlign: "center", backgroundColor: bgPhColor, color: '#fff'} }>{PhName}</th>
                    <th style={election === 'pru15' ? width < breakpoint ? {textAlign: "center", display: 'inline', backgroundColor: bgGpsColor, fontSize: '12px', width: '50px'} : {textAlign: "center", display: 'inline', backgroundColor: bgGpsColor} : {display: 'none'}}>{GpsName}</th>
                    <th style={width < breakpoint ? {textAlign: "center", backgroundColor: bgDllColor, fontSize: '12px', width: '50px'} : {textAlign: "center", backgroundColor: bgDllColor}}>{DllName}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    seatDunTable.map((data, idx) => {
                      let index = 'seatdun_' + idx;
                      if(data.stats[0].BN)
                        totaldBnWin = totaldBnWin + data.stats[0].BN.win;
                      if(data.stats[0].PH)
                        totaldPhWin = totaldPhWin + data.stats[0].PH.win;   
                      if(data.stats[0].DLL)
                        totaldDllWin = totaldDllWin + data.stats[0].DLL.win;

                      const party_list = [
                        {id: 'BN', win: data.stats[0].BN ? data.stats[0].BN.win : 0},
                        {id: 'PH', win: data.stats[0].PH ? data.stats[0].PH.win : 0},
                        {id: 'GPS', win: data.stats[0].GPS ? data.stats[0].GPS.win : 0},
                        {id: 'DLL', win: data.stats[0].DLL ? data.stats[0].DLL.win : 0},
                      ];
              
                      let winner = party_list.sort((a, b) => (a.win < b.win) ? 1 : -1)[0].id;

                      return (
                        <tr key={index}>
                          <td style={width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.stats[0].total_seat}</td>
                          <td style={width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.state_name}</td>
                          <td style={winner === 'BN' ? width < breakpoint ? {textAlign: "center", fontSize: '13px', backgroundColor: bgBnColor, color: '#fff'} : {textAlign: "center", backgroundColor: bgBnColor, color: '#fff'} : width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.stats[0].BN ? data.stats[0].BN.win : 0}</td>
                          <td style={winner === 'PH' ? width < breakpoint ? {textAlign: "center", fontSize: '13px', backgroundColor: bgPhColor, color: '#fff'} : {textAlign: "center", backgroundColor: bgPhColor, color: '#fff'} : width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.stats[0].PH ? data.stats[0].PH.win : 0}</td>
                          <td style={election === 'pru15' && winner === 'GPS' ? width < breakpoint ? {textAlign: "center", display: 'inline', fontSize: '13px', backgroundColor: bgGpsColor, color: '#fff'} : {textAlign: "center", display: 'inline', backgroundColor: bgGpsColor, color: '#fff'} : {display: "none"}}>{data.stats[0].GPS ? data.stats[0].GPS.win : 0}</td>
                          <td style={winner === 'DLL' ? width < breakpoint ? {textAlign: "center", fontSize: '13px', backgroundColor: bgDllColor, color: '#fff'} : {textAlign: "center", backgroundColor: bgDllColor, color: '#fff'} : width < breakpoint ? {textAlign: "center", fontSize: '13px'} : {textAlign: "center"}}>{data.stats[0].DLL ? data.stats[0].DLL.win : 0}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </div>

      <div class="decision-content-wrapper hide-mobile style4 desicion-bg-1 mt-4">
        <div class={(seatType == 'PARLIMEN' ? 'active with-carrtet-left' : '') + ' decision-content-title title-bg-navy2 dec-hover'} onClick={() => { setSeatType('PARLIMEN'); }}>
          <span class="text-title">PETA KERUSI PARLIMEN</span>
        </div>
        <div class={(seatType == 'DUN' ? 'active with-carrtet-left' : '') + ' decision-content-title title-bg-navy2 dec-hover'} onClick={() => { setSeatType('DUN'); }}>
          <span class="text-title">PETA KERUSI DEWAN UNDANGAN NEGERI</span>
        </div>

        <div class=" decision-table-content decision-bg-1">

          <div id="map-wrapper">
            <MalaysiaMap />
          </div>
          <div class="map-navigation-controll-wrapper">
            <img class="map-navigation-controll nav-map-zoom-in" src="/asset/image/map-zoom-in.svg" />
            <img class="map-navigation-controll nav-map-zoom-out" src="/asset/image/map-zoom-out.svg" />
            <img class="map-navigation-controll nav-map-zoom-reset" src="/asset/image/map-zoom-reset.svg" />
          </div>

          {
            seatParliamentTable?.map((data, idx) => {
              let index = 'seatparliamenttable_' + idx;
              let state_map = data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','');
              bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
              bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
              bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
              bgDllColor = 'silver';
      
              const party_list = [
                {id: 'BN', win: data.stats[0].BN ? data.stats[0].BN.win : 0},
                {id: 'PH', win: data.stats[0].PH ? data.stats[0].PH.win : 0},
                {id: 'GPS', win: data.stats[0].GPS ? data.stats[0].GPS.win : 0},
                {id: 'DLL', win: data.stats[0].DLL ? data.stats[0].DLL.win : 0},
              ];
      
              let winner = party_list.sort((a, b) => (a.win < b.win) ? 1 : -1)[0].id;
              console.log('winner: ' + winner);
              
              if(winner === 'BN')
                $('#' + state_map).css('fill', bgBnColor);
      
              if(winner === 'PH')
                $('#' + state_map).css('fill', bgPhColor);
      
              if(winner === 'GPS')
                $('#' + state_map).css('fill', bgGpsColor);
                
              if(winner === 'DLL')
                $('#' + state_map).css('fill', bgDllColor);

              return (
              <div key={index} id={'P_' + data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','')} class="map-hover-info-wrapper" style={{display: 'none'}}>
                <div class="row">
                  <div class="map-tip-head-title">
                    <strong>{data.state_name}</strong>
                    <div class="seat-info">{data.stats[0].total_seat} KERUSI PARLIMEN</div>
                  </div>
                  <div class="flag-wrapper">
                    <img src={'/asset/image/flags/' + data.state_name.toLowerCase().replace('.','').replace('.','').replace(' ','').replace(' ','') + '.svg'} />
                  </div>
                  <div class="parlement-seat-wrapper">
                    <div class="row">
                    <div class="col-6 mb-3">
                      <div class="party-initial-sym" style={{paddingLeft: '9px', color: '#fff', backgroundColor: bgBnColor}}>{BnName}</div> <div class="seat-info-sub">{data.stats[0].BN ? data.stats[0].BN.win : 0} KERUSI</div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '5px', color: '#fff', backgroundColor: bgPhColor}}>{PhName}</div> <div class="seat-info-sub">{data.stats[0].PH ? data.stats[0].PH.win : 0} KERUSI</div>
                      </div>
                      <div class="col-6 mb-3" style={data.state_name === 'SARAWAK' && election === 'pru15' ? {display: 'inline'} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '4px', backgroundColor: bgGpsColor}}>{GpsName}</div> <div class="seat-info-sub">{data.stats[0].GPS ? data.stats[0].GPS.win : 0} KERUSI</div>
                      </div>                     
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '5px', backgroundColor: bgDllColor}}>{DllName}</div> <div class="seat-info-sub">{data.stats[0].DLL ? data.stats[0].DLL.win : 0} KERUSI</div>
                      </div>                                    
                    </div>
                  </div>
                </div>
              </div>)
            })
          }

          {
            seatDunTable.map((data, idx) => {
              let index = 'seatduntable_' + idx;
              bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
              bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
              bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
              bgDllColor = 'silver';

              return (
              <div key={index} id={'D_' + data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','')}  class="map-hover-info-wrapper" style={{display: 'none'}}>
                <div class="row">
                  <div class="map-tip-head-title">
                    <strong>{data.state_name}</strong>
                    <div class="seat-info">{data.stats[0].total_seat} KERUSI DUN</div>
                  </div>
                  <div class="flag-wrapper">
                    <img src={'/asset/image/flags/' + data.state_name.toLowerCase().replace('.','').replace('.','').replace(' ','').replace(' ','') + '.svg'} />
                  </div>
                  <div class="parlement-seat-wrapper">
                    <div class="row">
                      <div class="col-6 mb-3">
                      <div class="party-initial-sym" style={{paddingLeft: '9px', color: '#fff', backgroundColor: bgBnColor}}>{BnName}</div> <div class="seat-info-sub">{data.stats[0].BN ? data.stats[0].BN.win : 0} KERUSI</div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '5px', color: '#fff', backgroundColor: bgPhColor}}>{PhName}</div> <div class="seat-info-sub">{data.stats[0].PH ? data.stats[0].PH.win : 0} KERUSI</div>
                      </div>
                      <div class="col-6 mb-3" style={data.state_name === 'SARAWAK' && election === 'pru15' ? {display: 'inline'} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '4px', backgroundColor: bgGpsColor}}>{GpsName}</div> <div class="seat-info-sub">{data.stats[0].GPS ? data.stats[0].GPS.win : 0} KERUSI</div>
                      </div>                     
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '5px', backgroundColor: bgDllColor}}>{DllName}</div> <div class="seat-info-sub">{data.stats[0].DLL ? data.stats[0].DLL.win : 0} KERUSI</div>
                      </div>                                    
                    </div>
                  </div>
                </div>
              </div> )
            })
          }         
          
        </div>
      </div>
    </>
  );
}

export default Results;
