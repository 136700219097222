import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TopTicker from "../components/TopTicker";
import SearchBar from "../components/SearchBar";
import Footer from "../components/Footer";


const Layout = ({ children }) => {
  const [urlString, seturlString] = useState('');

  useEffect(( ) => {
    seturlString(window.location.href.toString().includes('ticker'));
  },[]);

  if(urlString)
  {
    return (
      <div>
        <TopTicker />
      </div >
    );
  }else{
    return (
      <>
        <SearchBar />
        <main>{children}</main>
        <Footer />
      </>
    );
  }
};

export default Layout;
