import { Link } from "react-router-dom";
import { Nav, Container, Row } from "react-bootstrap";
import { Textfit } from 'react-textfit';
import React, { useEffect, useState } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ContentLoader from "react-content-loader";

function Infographic(props) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [tallyData, setTally] = useState([]);
    const [electionStats, setElectionStat] = useState({
        Parliament_Seats: {
            totals: 0
        },
        Parliament_Party: []
    });
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 767;

    function getElectionCandidateTotal() {
        let total = 0
        electionStats.Parliament_Party.forEach((item) => {
            total += item.candidates
        })

        return total;
    }

    function getProgressData() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/overall/tally_bar?election=${props.election}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setTally(result.tally);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }


    function getElectionSeats() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/overall/election_stats?election=${props.election}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setElectionStat(result);
                },
                (error) => {
                    setIsLoaded(true);
                }
            )
    }

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    useEffect(() => {
        setIsLoaded(false)
        setTally([])
        getElectionSeats()
        getProgressData()
    }, [props.election, props.state])

    return (
        <div>
            <div class="decision-content-wrapper style2 mt-1">
                <div class="decision-content-title">
                    <span class="text-title">KEPUTUSAN { props.election !== 'pru15' ? 'RASMI' : 'TERKINI (RASMI)' }</span>
                </div>

                <div class="decision-body">
                    <div class="body-title-1">MAJORITI MUDAH : 112</div>

                    <div class="abbr-wrapper row d-flex">

                        {!isLoaded ? (
                            Array.from({ length: 12 }).map(() => {
                                return (
                                    <div class="abbr-item col-4 d-flex justify-content-center" >
                                        <ContentLoader
                                            speed={2}
                                            width={240}
                                            height={65}
                                            viewBox="0 0 240 65"
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                        >
                                            <rect x="8" y="1" rx="3" ry="3" width="144" height="65" />
                                            <rect x="179" y="-2" rx="3" ry="3" width="59" height="65" />
                                        </ContentLoader>
                                    </div>
                                )
                            })
                        ) : (
                            tallyData.map((data, idx) => {
                                const fontColor = data.coalition_color_font ? data.coalition_color_font : '#fff';
                                if(idx > 7 || data.win === 0)
                                    return
                                else
                                return (
                                    <div class="abbr-item col-3 d-flex" style={{paddingLeft: '5%'}} key={idx}>
                                            <div class="abbr-title" style={{
                                                backgroundColor: data.coalition_color,
                                                color: fontColor,
                                                maxWidth: width < breakpoint ? '70%' : '50%',
                                                minWidth: width < breakpoint ? '70%' : '50%',
                                                height: width < breakpoint ? '80%' : '76%',
                                                padding: width < breakpoint ? '5px 5px' : '5px 10px',
                                                lineHeight: '150%',
                                            }}>
                                                <Textfit mode="single" max={width < breakpoint ? 50 : 40}>
                                                    {data.coalition_abbreviation.replace(/ .*/,'')}
                                                </Textfit>
                                            </div>
                                            <div class="abbr-count">{data.win}</div>
                                    </div>
                                )
                            })
                        )}



                    </div>

                    <Row>
                        <div class="decision-progress-wrapper">
                            <ProgressBar >

                                {
                                    tallyData.map((data, idx) => {
                                        const fontColor = data.coalition_color_font ? data.coalition_color_font : '#fff';
                                        if(data.win <= 0)
                                            return
                                        else
                                        return (
                                            <ProgressBar
                                                style={
                                                    { backgroundColor: data.coalition_color, color: data.coalition_color_font, fontSize: '16px', fontWeight: 'bold' }
                                                }
                                                striped={false} now={(data.win / 222) * 1000} key={idx} label={data.win >= 10? data.win : ''} />
                                        )
                                    })
                                }

                            </ProgressBar>
                        </div>
                    </Row>


                    <div class="decision-info-detail-wrapper row" style={{marginBottom: '0px'}}>
                        <div class="decision-detail-item col">
                            <span class="decision-count">{electionStats.Parliament_Seats.totals}</span> JUMLAH KERUSI
                        </div>

                        <div class="decision-detail-item col col1">
                            <span class="decision-count">{getElectionCandidateTotal()}</span> CALON BERTANDING
                        </div>

                        <div class="decision-detail-item col no-border">
                            <span class="decision-count">{electionStats.Parliament_Party.length}</span> PARTI BERTANDING
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

Infographic.defaultProps = {
    'election': 'pru14'
}

export default Infographic;
