import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";

// Layout
import Layout from "./layout/Layout";

// pages
import Results from "./pages/Results";
import ResultsDetail from "./pages/ResultsDetail";
import ResultsLive from "./pages/ResultsLive";
import ResultsLiveDetail from "./pages/ResultsLiveDetail";
import Nominations from "./pages/Nominations";
import NominationsDetail from "./pages/NominationsDetail";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import Facts from "./pages/Facts";
import FactsDetail from "./pages/FactsDetail";
import WidgetTicker from "./pages/WidgetTicker";
import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <Layout>
      <Container>
        <Routes>
          <Route path="/" element={<ResultsLive />} exact />
          <Route path="/keputusan/:election" element={<Results />} />
          <Route path="/keputusan/:election/:state" element={<ResultsDetail />} />
          <Route path="/penamaan/:election" element={<Nominations />} />
          <Route path="/penamaan/:election/:state" element={<NominationsDetail />} />
          <Route path="/:election" element={<ResultsLive />} />
          <Route path="/:election/:state" element={<ResultsLiveDetail />} />                      
          <Route path="/berita" element={<News />} />
          <Route path="/berita/:title/:id" element={<NewsDetail />} />
          <Route path="/fakta" element={<Facts />} />
          <Route path="/fakta/:title/:id" element={<FactsDetail />} />
          <Route path="/widget/ticker" widget={false} element={<WidgetTicker />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </Layout>
  );
};


export default App;