import { useEffect } from 'react';
import { parse } from 'rss-to-json';

export function loadScript(url) {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    }
};

export function setWithExpiry(key, value, ttl) {
    const now = new Date()

    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}


export function convertToSlug(Text) {
    return Text.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^a-zA-z0-9-\u4e00-\u9eff]+/g, '');
}


export async function getPru15API() {
    return parse('https://tvstv.my/category/politik/feed')
}


export async function getFactsAPI() {
    return parse('https://tvstv.my/category/tahukah-anda/feed')
}