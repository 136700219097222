import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import ContentLoader from "react-content-loader"

function TopTicker() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [notifications, setItems] = useState([]);


    useEffect(() => {

        fetch(`https://election.tvstv.my/sarawakdecides/api/notifications`, {
            'no-cors': 'true',
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.list);
                },
                (error) => {
                    console.log(error)
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    return (
        <div class="top-ticker">

            <div class="red-indicator-running">
                TERKINI
            </div>
            <div class="running-info-wrapper">
                {
                    !isLoaded ? (
                        Array.from({ length: 4 }).map((data, idx) => {
                            let index = 'ticker_' + idx;
                            return (
                                <ContentLoader
                                    key={index}
                                    speed={1}
                                    width={350}
                                    height={15}
                                    viewBox="0 0 350 15"
                                    backgroundColor="rgba(225,225,225,0.2)"
                                    foregroundColor="rgba(225,225,225,0.4)"
                                >
                                    <rect x="11" y="1" rx="3" ry="3" width="300" height="11" />
                                    <circle cx="335" cy="6" r="4" />
                                </ContentLoader>

                            )

                        })

                    ) : ''
                }

                <Marquee pauseOnHover={true} speed={30} gradient={false}>

                    {
                        notifications.map((item, idx) => {
                            let index = 'notification_' + idx;
                            return (
                                <p key={index}>
                                    {item.notify_msg} <span class="circle-sym">◍</span>
                                </p>
                            )
                        })
                    }
                </Marquee>
            </div>
        </div >
    );
}

export default TopTicker;
