import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { convertToSlug, getFactsAPI } from "../js/Helper";

function FaktaMenarik() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [news, setItems] = useState({});
  const [factsList, setFactsList] = useState([]);
  const { id } = useParams();

  var regex = (/(https?:\/\/[^ ]*\.(?:gif|png|jpg|jpeg))/i);

  function getImageFromStr(str) {
      if (new RegExp(regex).test(str)) {
          const imgurl = regex.exec(str)[1];

          if (imgurl)
              return imgurl;
      }
  }



  function getFacts() {
      let facts = localStorage.getItem('facts')


      if (facts) {
          facts = JSON.parse(facts)

          console.log(facts)
          setItems(facts[id])
          setFactsList(facts)
      } else {
          getFactsAPI().then((rss) => {
              localStorage.setItem('facts', JSON.stringify(rss.items))
              localStorage.setItem('facts_expiry', (new Date()).getTime() + (1000 * 360))
              getFacts()

          })
      }
  }

  useEffect(() => {
      getFacts()
      document.title = "Tahukah Anda"; 
  }, [id])

  useEffect(() => {
    document.title = "Tahukah Anda"; 
  }, []);  

    return (
        <div>

            <div class="red-separator"></div>

            <div class="hot-news-wrapper">
                <div class="row ">

                    <div class="col">


                        <div class=" most-popular-wrapper">
                            {
                                factsList.map((item, idx) => {
                                    return (
                                        <div class="card mb-3 news-item">
                                            <div class="row g-0">
                                                <Link to={`/fakta/${convertToSlug(item.title)}/${idx}`} className="nav-link px-0 link-secondary">
                                                    <div class="col-md-4">
                                                        <img src={getImageFromStr(item.description)} class="img-fluid rounded-start" />
                                                    </div>
                                                </Link>
                                                <div class="col-md-8">
                                                    <div class="card-body">
                                                        <h5 class="card-title">

                                                            <Link to={`/fakta/${convertToSlug(item.title)}/${idx}`} className="nav-link px-0 link-secondary" dangerouslySetInnerHTML={{ __html: item.title }}>
                                                            </Link>
                                                        </h5>
                                                        <p class="card-text"><small class="text-muted">{item.author} . {moment(item.created).format('MMMM DD, YYYY')}</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>

        </div >
    );
}

export default FaktaMenarik;
