import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";

function StateMiddleMenuLive(props) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [middleMenus, setItems] = useState([]);

    const settings = {
        lazyload: true,
        nav: false,
        mouseDrag: true,
        items: 10,
    };


    function getMiddleMenu() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/states?election=${props.election}&type=parliament`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.state);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    useEffect(() => {

        getMiddleMenu()

    }, [])

    return (
        <>
            <Row></Row>

            <div class="section-raya">
                PILIHAN RAYA UMUM <span>KE-{props.election.replace('pru','')}</span>
            </div>


            <div class="middle-menu d-flex justify-content-center" >


                {!isLoaded ? Array.from({ length: 8 }).map(() => {
                    return (
                        <ContentLoader
                            speed={2}
                            width={150}
                            height={40}
                            viewBox="0 0 150 40"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="9" y="3" rx="3" ry="3" width="148" height="24" />
                        </ContentLoader>
                    )

                }) : ''}


                {
                    middleMenus.map((item, idx, arr) => {
                        if (idx + 1 === arr.length) {
                            return (

                                <div class="menu-item-last" key={idx}>
                                    <Link to={`/${props.election}/${item.state_name.toLowerCase()}`} className="nav-link px-2 link-secondary">
                                        {item.state_name}
                                    </Link>
                                </div>
                            )
                        } else {
                            return (

                                <div class="menu-item" key={idx}>
                                    <Link to={`/${props.election}/${item.state_name.toLowerCase()}`} className="nav-link px-2 link-secondary">
                                        {item.state_name}
                                    </Link>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </>
    );
}

StateMiddleMenuLive.defaultProps = {
    'election': 'pru15'
}

export default StateMiddleMenuLive;
