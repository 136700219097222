import { Link } from "react-router-dom";
import { Nav, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { parse } from 'rss-to-json';
import ContentLoader from "react-content-loader"
import { convertToSlug, getPru15API } from "../js/Helper";

function Pru15() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [Pru15s, setItems] = useState([]);
    const regex = /(<([^>]+)>)/ig;

    function getPru15() {

        getPru15API().then((rss) => {
            setItems(rss.items)
            localStorage.setItem('news', JSON.stringify(rss.items))
            localStorage.setItem('news_expiry', (new Date()).getTime() + (1000 * 360))

            setIsLoaded(true)
        })
    }

    let regex2 = (/(https?:\/\/[^ ]*\.(?:gif|png|jpg|jpeg))/i);

    function getImageFromStr(str) {
        if (new RegExp(regex2).test(str)) {
            const imgurl = regex2.exec(str)[1];

            if (imgurl)
                return imgurl;
        }
    }

    useEffect(() => {


        const news = localStorage.getItem('news')

        if (news) {
            setItems(JSON.parse(news))
            setIsLoaded(true)
        } else {
            getPru15()
        }

        setTimeout(() => {
            let expiry = localStorage.getItem('news_expiry');
            if ((new Date()).getTime() > expiry) {

                localStorage.removeItem('news_expiry')
                localStorage.removeItem('news')
            }
        }, 1000)


    }, [])

    const Loading = () => (
        <div
            className="front-carausel"
        >
            <ContentLoader
                speed={2}
                width={450}
                height={200}
                viewBox="0 0 450 205"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="1" y="0" rx="3" ry="3" width="600" height="200" />
            </ContentLoader>
        </div>
    )

    const MainCarousel = () => {
        return (<>
            <Carousel className="front-carausel"
                showThumbs={false}
                swipeable={true}
                showIndicators={false}
                autoPlay={true}
                dynamicHeight={false}
                emulateTouch={true}
                stopOnHover={true}
                centerMode={false}
                infiniteLoop={true}
                ariaLabel={false}
                statusFormatter={() => { }}
            >
                {
                    Pru15s.map((item, idx) => {

                        const renderHTML = (rawHTML) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });

                        return (
                            <div>
                                <div class="overlay-slide-show">
                                    <span class="news-slide-title">
                                        <Link to={`/berita/${convertToSlug(item.title)}/${idx}`} classname="news-slide-title">{renderHTML(item.title)}</Link>
                                    </span>
                                    <div class="news-slide-content">
                                        {/*  {(item.description.replace(regex, ''))} */}
                                    </div>
                                    <div class="news-slide-footer">
                                        {`${item.author}`}
                                    </div>
                                </div>
                                <img src={getImageFromStr(item.description)} />

                            </div>
                        )
                    })
                }

            </Carousel>
        </>)
    }

    return (
        <>
            {isLoaded ? (<MainCarousel />) : (<Loading />)}
        </>
    );
}

export default Pru15;
