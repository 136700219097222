import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { convertToSlug, getPru15API } from "../js/Helper";

function News() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [news, setItems] = useState({});
    const [newsList, setNewsList] = useState([]);
    const { id } = useParams();


    var regex = (/(https?:\/\/[^ ]*\.(?:gif|png|jpg|jpeg))/i);

    function getImageFromStr(str) {
        if (new RegExp(regex).test(str)) {
            const imgurl = regex.exec(str)[1];

            if (imgurl)
                return imgurl;
        }
    }



    function getPru15() {
        let news = localStorage.getItem('news')


        if (news) {
            news = JSON.parse(news)

            console.log(news)
            setItems(news[id])
            setNewsList(news)
        } else {
            getPru15API().then((rss) => {
                localStorage.setItem('news', JSON.stringify(rss.items))
                localStorage.setItem('news_expiry', (new Date()).getTime() + (1000 * 360))
                getPru15()

            })
        }
    }

    useEffect(() => {
        getPru15()
        document.title = "Berita PRU15";
    }, [id])

    useEffect(() => {
        document.title = "Berita PRU15"; 
    }, []);       

    return (
        <div>

            <div class="red-separator"></div>

            <div class="hot-news-wrapper">
                <div class="row ">

                    <div class="col">


                        <div class=" most-popular-wrapper">
                            {
                                newsList.map((item, idx) => {
                                    return (
                                        <div class="card mb-3 news-item">
                                            <div class="row g-0">
                                                <Link to={`/berita/${convertToSlug(item.title)}/${idx}`} className="nav-link px-0 link-secondary">
                                                    <div class="col-md-4">
                                                        <img src={getImageFromStr(item.description)} class="img-fluid rounded-start" />
                                                    </div>
                                                </Link>
                                                <div class="col-md-8">
                                                    <div class="card-body">
                                                        <h5 class="card-title">
                                                            <Link to={`/berita/${convertToSlug(item.title)}/${idx}`} className="nav-link px-0 link-secondary" dangerouslySetInnerHTML={{ __html: item.title }}>
                                                            </Link>
                                                        </h5>
                                                        <p class="card-text"><small class="text-muted">{item.author} . {moment(item.created).format('MMMM DD, YYYY')}</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>

        </div >
    );
}

export default News;
