import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Textfit } from 'react-textfit';
import { useParams } from 'react-router-dom';
import StateMiddleMenuLive from "../components/StateMiddleMenuLive";
import InfoMapLive from "../components/InfoMapLive";
import $ from "jquery";

function ResultsLiveDetail() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [seatStateParliamentTable, setStateSeatParliamentTable] = useState([]);
    const [seatStateDunTable, setStateSeatDunTable] = useState([]); 
    const [showDecisionOverlay, setShowDecisionOverlay] = useState(false);

    let { election = 'pru15',state } = useParams();
    let totalpWin = 0;
    let totaldWin = 0;


    function handleDecisionDetail() {
        $('.decision-list-overlay').show();
    }

    function handleCloseDecisionDetail() {
        setShowDecisionOverlay(false)
    }

    function getPartyStateTable() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/state/tally_table?election=${election}&state=${state}`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setStateSeatParliamentTable(result.Parliament_Stats);
            setStateSeatDunTable(result.DUN_Stats);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    } 

    useEffect(() => {
        setStateSeatParliamentTable([]);
        setStateSeatDunTable([]);
        getPartyStateTable();
        document.title = "Keputusan " + election.toUpperCase() + " - " + state.toUpperCase(); 
    }, [election,state])

    useEffect(() => {
        document.title = "Keputusan " + election.toUpperCase() + " - " + state.toUpperCase(); 
    }, []);    

    return (
        <>

            <Row></Row>
            <StateMiddleMenuLive election={election} />
            <Row></Row>

            <div class="decision-content-wrapper style2 mt-1">

                <div class="decision-content-title">
                    <span class="text-title">{state.toUpperCase()}</span>
                </div>

                <div class="decision-table-content">

                    <div class="row">
                        <div class="col-3 px-5" style={state.includes('w.p.') || state.includes('pulau pinang') || state.includes('kedah') || state.includes('kelantan') || state.includes('terengganu') || state.includes('negeri sembilan') || state.includes('selangor') ? {display: "block"} : {display: "none"}}></div>
                        <div class={"col px-5" + (state.includes('w.p.') || state.includes('pulau pinang') || state.includes('kedah') || state.includes('kelantan') || state.includes('terengganu') || state.includes('negeri sembilan') || state.includes('selangor') ? "" : " position-wrapper")}>


                            <div class="body-title-1">KEDUDUKAN PARLIMEN</div>

                            <div class="row mt-4">
                                { seatStateParliamentTable.map((data, idx) => {
                                        const fontColor = data.color_font ? data.color_font : '#fff';
                                        if(idx > 3 || data.win === 0)
                                            if(idx === 0 || idx === 2)
                                                return (
                                                    <div style={{height: '70px'}} class="mb-4"></div>
                                                )
                                            else
                                                return
                                        else
                                        return (
                                            <div class="col-6 mb-4" key={idx}>
                                                <div class="abbr-title" style={{
                                                    backgroundColor: data.color,
                                                    color: fontColor,
                                                    maxWidth: '50%',
                                                    minWidth: '50%',
                                                    padding: '5px 20px',
                                                    lineHeight: '150%',
                                                }}>
                                                    <Textfit mode="single" max={40}>
                                                        {data.party.replace(/ .*/,'')}
                                                    </Textfit>
                                                </div>
                                                <div class="abbr-count">{data.win}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <Table bordered hover className="mt-3">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>PARTI</th>
                                        <th style={{textAlign: "center"}}>MENANG</th>
                                        <th style={{textAlign: "center"}}>BERTANDING</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        seatStateParliamentTable.map((data, idx) => {
                                            totalpWin = totalpWin + data.win;
                                            return (
                                                <tr key={idx}>
                                                  <td width={80} style={{
                                                    backgroundColor: data.color
                                                  }}></td>
                                                  <td>{data.party.toUpperCase()}</td>
                                                  <td style={{textAlign: "center"}}>{data.win}</td>
                                                  <td style={{textAlign: "center"}}>{data.run}</td>
                                                </tr>
                                              )
                                        })
                                    }
                                     <tr>
                                        <th colSpan={2} style={{textAlign: "center"}}>JUMLAH KERUSI</th>
                                        <td style={{textAlign: "center", fontWeight: '800'}}>{totalpWin}</td>
                                    </tr>                                   
                                </tbody>
                            </Table>
                        </div>
                        <div class="col-3 px-5" style={state.includes('w.p.') || state.includes('pulau pinang') || state.includes('kedah') || state.includes('kelantan') || state.includes('terengganu') || state.includes('negeri sembilan') || state.includes('selangor') ? {display: "block"} : {display: "none"}}></div>
                        <div class="col px-5" style={state.includes('w.p.') || state.includes('pulau pinang') || state.includes('kedah') || state.includes('kelantan') || state.includes('terengganu') || state.includes('negeri sembilan') || state.includes('selangor') ? {display: "none"} : {display: "block"}}>
                            <div class="body-title-1">KEDUDUKAN DUN {state?.toUpperCase() === 'SARAWAK' ? state?.toUpperCase() + ' (PRN12)' : state?.toUpperCase() === 'SABAH' ? state?.toUpperCase() + ' (2020)' : state?.toUpperCase() === 'MELAKA' ? state?.toUpperCase() + ' (2021)' : state?.toUpperCase() === 'JOHOR' ? state?.toUpperCase() + ' (2022)' : state?.toUpperCase()}</div>
                            <div class="row mt-4">
                                { seatStateDunTable?.map((data, idx) => {
                                        const fontColor = data.color_font ? data.color_font : '#fff';
                                        if(idx > 3 || data.win === 0)
                                            if(idx === 0 || idx === 2)
                                                return (
                                                    <div style={{height: '70px'}} class="mb-4"></div>
                                                )
                                            else
                                                return     
                                        else
                                        return (
                                            <div class="col-6 mb-4" key={idx}>
                                                <div class="abbr-title" style={{
                                                    backgroundColor: data.color,
                                                    color: fontColor,
                                                    maxWidth: '50%',
                                                    minWidth: '50%',
                                                    padding: '5px 20px',
                                                    lineHeight: '150%',
                                                }}>
                                                    <Textfit mode="single" max={40}>
                                                        {data.party.replace(/ .*/,'')}
                                                    </Textfit>
                                                </div>
                                                <div class="abbr-count">{data.win}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <Table bordered hover className="mt-3">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>PARTI</th>
                                        <th style={{textAlign: "center"}}>MENANG</th>
                                        <th style={{textAlign: "center"}}>BERTANDING</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        seatStateDunTable?.map((data, idx) => {
                                            totaldWin = totaldWin + data.win;
                                            return (
                                                <tr key={idx}>
                                                  <td width={80} style={{
                                                    backgroundColor: data.color
                                                  }}></td>
                                                  <td>{data.party.toUpperCase()}</td>
                                                  <td style={{textAlign: "center"}}>{data.win}</td>
                                                  <td style={{textAlign: "center"}}>{data.run}</td>
                                                </tr>
                                              )
                                        })
                                    }
                                    <tr>
                                        <th colSpan={2} style={{textAlign: "center"}}>JUMLAH KERUSI</th>
                                        <td style={{textAlign: "center", fontWeight: '800'}}>{totaldWin}</td>
                                    </tr>                                    
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </div>
            </div>

            <InfoMapLive />

        </>


    );
}

export default ResultsLiveDetail;