import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container fluid="md" className="mt-5">
      <Row>
        <Col>
          <div class="error-template">
            <h1>
              Oops!</h1>
            <h2>
              404 Not Found</h2>
            <div class="error-details">
              Sorry, an error has occured, Requested page not found!
            </div>
            <div class="error-actions">

            </div>
          </div>
        </Col>
      </Row>
    </Container >
  );
};

export default NotFound;
