import { Link } from "react-router-dom";
import { Nav, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { parse } from 'rss-to-json';
import ContentLoader from "react-content-loader"
import { convertToSlug, getFactsAPI } from "../js/Helper";

function Facts() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [Facts, setItems] = useState([]);
    const regex = /(<([^>]+)>)/ig;

    function getFacts() {

        getFactsAPI().then((rss) => {
            setItems(rss.items)
            localStorage.setItem('facts', JSON.stringify(rss.items))
            localStorage.setItem('facts_expiry', (new Date()).getTime() + (1000 * 360))

            setIsLoaded(true)
        })
    }

    let regex2 = (/(https?:\/\/[^ ]*\.(?:gif|png|jpg|jpeg))/i);

    function getImageFromStr(str) {
        if (new RegExp(regex2).test(str)) {
            const imgurl = regex2.exec(str)[1];

            if (imgurl)
                return imgurl;
        }
    }

    useEffect(() => {

        const facts = localStorage.getItem('facts')

        if (facts) {
            setItems(JSON.parse(facts))
            setIsLoaded(true)
        } else {
            getFacts()
        }

        setTimeout(() => {
            let expiry = localStorage.getItem('facts_expiry');
            if ((new Date()).getTime() > expiry) {

                localStorage.removeItem('facts_expiry')
                localStorage.removeItem('facts')
            }
        }, 1000)


    }, [])

    const Loading = () => (
        <div
            className="front-carausel"
        >
            <ContentLoader
                speed={2}
                width={450}
                height={200}
                viewBox="0 0 450 205"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="1" y="0" rx="3" ry="3" width="600" height="200" />
            </ContentLoader>
        </div>
    )

    const MainCarousel = () => {
        return (<>
            <Carousel className="front-carausel"
                showThumbs={false}
                swipeable={true}
                showIndicators={false}
                autoPlay={true}
                dynamicHeight={false}
                emulateTouch={true}
                stopOnHover={true}
                centerMode={false}
                infiniteLoop={true}
                ariaLabel={false}
                statusFormatter={() => { }}
            >
                {
                    Facts.map((item, idx) => {

                        const renderHTML = (rawHTML) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });

                        return (
                            <div>
                                <Link to={`/fakta/${convertToSlug(item.title)}/${idx}`}>
                                    <div class="overlay-slide-show2">
                                        <div class="news-slide-content">
                                            {/*  {(item.description.replace(regex, ''))} */}
                                        </div>
                                    </div>
                                    <img style={{width:'100%',height:'100%'}} src={getImageFromStr(item.description)} />
                                </Link>
                            </div>
                        )
                    })
                }

            </Carousel>
        </>)
    }

    return (
        <>
            {isLoaded ? (<MainCarousel />) : (<Loading />)}
        </>
    );
}

export default Facts;
