const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <>
      <footer className='text-center text-capitalize mt-3 mb-2'>
        {year} &copy; TVS
      </footer>
    </>
  )
}

export default Footer
