import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Textfit } from 'react-textfit';
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import Logo from './image/logo.jpg';
import MalaysiaDecides from './image/malaysia-decides.png';

function TopNavLive(props) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [seats, setItems] = useState([]);
    const [states, setStates] = useState([]);
    const [seatStateParliamentTable, setStateSeatParliamentTable] = useState([]);
    const [seatStateDunTable, setStateSeatDunTable] = useState([]); 
    const [resultStateParliament, setStateResultParliament] = useState([]);
    const [resultStateDun, setStateResultDun] = useState([]);   
    const [resultOverlayP, setResultOverlayP] = useState([]);   
    const [resultOverlayD, setResultOverlayD] = useState([]);   

    const [state, setState] = useState('sarawak');
    const [election, setElection] = useState('pru15');
    const [show, setShow] = useState(false);
    const [showDUN, setShowDUN] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseDUN = () => setShowDUN(false);
    const handleShow = () => setShow(true);
    const url = window.location.pathname.split("/");
    const location = useLocation();

    let totalpWin = 0;
    let totaldWin = 0;

    function handleFullScreen() {
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

    function truncate(str) {
        return str.length > 34 ? str.substring(0, 32) + "..." : str;
    }


    function truncateAbbr(str) {
        return str.length > 3 ? str.substring(0, 3): str;
    }

    function getState() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/states?election=${election}&type=parliament`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setStates(result.state);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    } 
    
    function getPartyStateTable() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/state/tally_table?election=${election}&state=${state}`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setStateSeatParliamentTable(result.Parliament_Stats);
            setStateSeatDunTable(result.DUN_Stats);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
    
    function getResultParliament() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/state/result_parliament?election=${election}&state=${state}`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setStateResultParliament(result.Parliament_Results);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
    
    function getResultDun() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/state/result_dun?election=${election}&state=${state}`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setStateResultDun(result.DUN_Results);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }   

    const [showParlimentListModal, setShowParlimentListModal] = useState(false);
    const [showDunListModal, setShowDunListModal] = useState(false);

    const handleCloseParlimentList = () => {
        setShowParlimentListModal(false);
        setShow(true);
    }

    const handleCloseDunList = () => {
        setShowDunListModal(false);
        setShowDUN(true);
    }

    const handleShowParlimentList = () => {
        setShowParlimentListModal(true);
        handleClose()
        getState();
    }

    const handleShowDunList = () => {
        setShowDunListModal(true);
        handleClose()
        getState();
    }

    const [showDesicionList, setShowDesicionList] = useState(false);
    const [showDesicionListDun, setShowDesicionListDun] = useState(false);
    const [showFaktaMenarik, setShowFaktaMenarik] = useState(false);
    const [showFaktaMenarikDetail, setShowFaktaMenarikDetail] = useState(false);

    const handleCloseDesicionList = () => {
        setShowDesicionList(false);
        setShowParlimentListModal(false);
        setShow(false);
    }

    const handleBackDesicionList = () => {
        setShowDesicionList(false);
    }

    const handleCloseDesicionListDun = () => {
        setShowDesicionListDun(false);
        setShowDunListModal(false);
        setShowDUN(false);
    }   

    const handleBackDesicionListDun = () => {
        setShowDesicionListDun(false);
    }


    function handleShowDesicionList(result) {
        setResultOverlayP(result);
        setShowParlimentListModal(true);
        setShowDesicionList(true);
    }

    function handleShowDesicionDunList(result) {
        setResultOverlayD(result);
        setShowDunListModal(true);
        setShowDesicionListDun(true);
    }

    const handleCloseFaktaMenarik = () => {
        setShowFaktaMenarik(false);
    }
    const handleShowFaktaMenarik = () => {
        setShowFaktaMenarik(true);
    }

    const handleCloseFaktaMenarikDetail = () => {
        setShowFaktaMenarik(false);
        setShowFaktaMenarikDetail(false);
    }
    const handleShowFaktaMenarikDetail = () => {
        setShowFaktaMenarikDetail(true);
    }

    function getSeat() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/states?election=PRU14&type=parliament`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.state);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    function handleClickParlement(item) {
        setState(item);
        setShow(true);
    }

    function handleClickDUN(item) {
        setState(item);
        setShowDUN(true);
    }

    useEffect(() => {
        console.log(election);
        getSeat();
    }, [])   

    useEffect(() => {
        if (typeof url[2] != 'undefined') {
            setElection(url[2])
        }     
    }, [location])

    useEffect(() => {
        setStateSeatParliamentTable([]);
        setStateSeatDunTable([]);
        setStateResultParliament([]);
        setStateResultDun([]);
        setResultOverlayP([]);
        setResultOverlayD([]);
        getPartyStateTable();
        getResultParliament();
        getResultDun();
    }, [election,state])

    return (

        <div class="navbar-main">
            <Modal animation={false} show={show} onHide={handleClose} data-backdrop="false">
                <a class="close-wrapper" onClick={handleClose}>
                    <span>X</span>
                </a>
                <Modal.Body style={{overflow: 'auto', maxHeight: '100vh'}}>
                    <div class="flag-modal-wrapper">
                        <center>
                            <img class="flag-modal" src={'/asset/image/flags/' + state?.replace('.','').replace('.','').replace(' ','').toLowerCase() + '.svg'} />
                        </center>
                    </div>
                    <div class="mt-2 modal-title-center">
                        KERUSI PARLIMEN {state?.toUpperCase()}
                    </div>

                    <div class="row mt-1 d-flex">
                        {
                            seatStateParliamentTable?.map((data, idx) => {
                                let index = 'arr1' + idx;
                                const fontColor = data.color_font ? data.color_font : '#fff';
                                if(idx > 3 || data.win === 0)
                                    return
                                else
                                return (
                                    <div key={index} class="col-6 mb-3" style={{paddingLeft: '10%'}}>
                                        <div class="abbr-title" style={{
                                                backgroundColor: data.color,
                                                color: fontColor,
                                                maxWidth: '40%',
                                                minWidth: '40%',
                                                padding: '5px 10px',
                                                lineHeight: '150%',
                                            }}>
                                                <Textfit mode="single" max={50}>
                                                    {data.party.replace(/ .*/,'')}
                                                </Textfit>
                                            </div>
                                            <div class="abbr-count2">{data.win}</div>
                                    </div>
                                )
                            })
                        }
                        
                        <Table bordered className="table-striped">
                            <thead>
                                <tr style={{backgroundColor: '#fff'}}>
                                    <th></th>
                                    <th>PARTI</th>
                                    <th style={{textAlign: "center"}}>MENANG</th>
                                    <th style={{textAlign: "center"}}>BERTANDING</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    seatStateParliamentTable?.map((data, idx) => {
                                        let index = 'arr2' + idx;
                                        totalpWin = totalpWin + data.win;
                                        return (
                                            <tr key={index} style={{backgroundColor: '#fff'}}>
                                                  <td width={50}><div class={`party-box-color `} style={{
                                                    backgroundColor: data.color
                                                  }}></div></td>
                                                  <td>{data.party.toUpperCase()}</td>
                                                  <td style={{textAlign: "center"}}>{data.win}</td>
                                                  <td style={{textAlign: "center"}}>{data.run}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <th colSpan={2} style={{textAlign: "center", backgroundColor: '#fff'}}>JUMLAH KERUSI</th>
                                    <td style={{textAlign: "center", backgroundColor: '#fff'}}>{totalpWin}</td>
                                </tr>                                
                            </tbody>
                        </Table>
                        <div>
                            <center><a href="#" onClick={handleShowParlimentList} class="nav-link px-2 link-secondary btn-detail-senarai-selangor">SENARAI PARLIMEN {state?.toUpperCase()} &#187;</a></center>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false} show={showDUN} onHide={handleCloseDUN} data-backdrop="false">
                <a class="close-wrapper" onClick={handleCloseDUN}>
                    <span>X</span>
                </a>
                <Modal.Body style={{overflow: 'auto', maxHeight: '100vh'}}>

                    <div class="flag-modal-wrapper">
                        <center>
                            <img class="flag-modal" src={'/asset/image/flags/' + state?.replace(' ','').toLowerCase() + '.svg'} />
                        </center>
                    </div>
                    <div class="mt-2 modal-title-center">
                        KERUSI DUN {election == 'pru15' && state?.toUpperCase() == 'SARAWAK' ? state?.toUpperCase() + ' (PRN12)' : election == 'pru15' && state?.toUpperCase() == 'SABAH' ? state?.toUpperCase() + ' (2020)' : election == 'pru15' && state?.toUpperCase() == 'MELAKA' ? state?.toUpperCase() + ' (2021)' : election == 'pru15' && state?.toUpperCase() == 'JOHOR' ? state?.toUpperCase() + ' (2022)' : state?.toUpperCase()}  
                    </div>

                    <div class="row mt-1 d-flex">
                        {
                            seatStateDunTable?.map((data, idx) => {
                                let index = 'arr1' + idx;
                                const fontColor = data.color_font ? data.color_font : '#fff';
                                if(idx > 3 || data.win === 0)
                                    return       
                                else
                                return (
                                    <div key={index} class="col-6 mb-3" style={{paddingLeft: '10%'}}>
                                        <div class="abbr-title" style={{
                                                backgroundColor: data.color,
                                                color: fontColor,
                                                maxWidth: '40%',
                                                minWidth: '40%',
                                                padding: '5px 10px',
                                                lineHeight: '150%',
                                            }}>
                                                <Textfit mode="single" max={50}>
                                                    {data.party.replace(/ .*/,'')}
                                                </Textfit>
                                            </div>
                                            <div class="abbr-count2">{data.win}</div>
                                    </div>
                                )
                            })
                        }

                        <Table bordered className="table-striped">
                            <thead>
                                <tr style={{backgroundColor: '#fff'}}>
                                    <th></th>
                                    <th>PARTI</th>
                                    <th style={{textAlign: "center"}}>MENANG</th>
                                    <th style={{textAlign: "center"}}>BERTANDING</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    seatStateDunTable?.map((data, idx) => {
                                        let index = 'arr2' + idx;
                                        totaldWin = totaldWin + data.win;
                                        return (
                                            <tr key={index} style={{backgroundColor: '#fff'}}>
                                                  <td width={50}><div class={`party-box-color `} style={{
                                                    backgroundColor: data.color
                                                  }}></div></td>
                                                  <td>{data.party.toUpperCase()}</td>
                                                  <td style={{textAlign: "center"}}>{data.win}</td>
                                                  <td style={{textAlign: "center"}}>{data.run}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <th colSpan={2} style={{backgroundColor: '#fff'}}>JUMLAH KERUSI</th>
                                    <td style={{textAlign: "center", backgroundColor: '#fff'}}>{totaldWin}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <div>
                            <center><a href="#" onClick={handleShowDunList} class="nav-link px-2 link-secondary btn-detail-senarai-selangor">SENARAI DUN {state?.toUpperCase()} &#187;</a></center>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>            

            <Modal animation={false} show={showParlimentListModal} data-backdrop="false">
                <div class="close-wrapper" onClick={handleCloseParlimentList}>
                    <span>X</span>
                </div>
                <Modal.Body style={{overflow: 'auto', maxHeight: '100vh', width: '100vw'}}>
                    <div class="mt-2 modal-title-center">
                        SENARAI PARLIMEN {state?.toUpperCase()}
                    </div>
                    <div class="row">
                        <div class="map-city-body mt-4">
                            {
                                resultStateParliament.map((data, idx) => {
                                    let index = 'arr3' + idx;
                                    return (
                                        <div key={index} class="mb-1">
                                            <a style={{fontSize: '18px', fontWeight: '400'}} onClick={() => handleShowDesicionList(data)}>{data.parliament_code} {data.parliament_name}</a>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal animation={false} show={showDunListModal} data-backdrop="false">
                <div class="close-wrapper" onClick={handleCloseDunList}>
                    <span>X</span>
                </div>
                <Modal.Body style={{overflow: 'auto', maxHeight: '100vh', width: '100vw'}}>
                    <div class="mt-2 modal-title-center">
                        SENARAI DUN {state?.toUpperCase()}
                    </div>
                    <div class="row">
                        <div class="map-city-body mt-4">
                            {
                                resultStateDun?.map((data, idx) => {
                                    let index = 'arr3' + idx;
                                    return (
                                        <div key={index} class="mb-1">
                                            <a style={{fontSize: '18px', fontWeight: '400'}} onClick={() => handleShowDesicionDunList(data)}>{data.dun_code} {data.dun_name}</a>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal animation={false} show={showDesicionList} data-backdrop="false">
                <div class="style3">
                    <div class="decision-content-title2 bg-red">
                        <span class="prev" onClick={handleBackDesicionList}><img src="/asset/image/chevron-left.svg" /></span>
                        <span class="text-title">KEPUTUSAN TERKINI (RASMI)</span>
                        <span class="close"></span>
                    </div>
                </div>
                <Modal.Body style={{overflow: 'auto', maxHeight: '100vh'}}>
                    <div class="col mb-2">
                        <div class="body-title-2" style={{fontSize: '20px'}}>JUMLAH UNDI</div>
                        <div class="body-title-2" style={{marginTop: '-8px'}}>{resultOverlayP?.parliament_code} {resultOverlayP?.parliament_name}</div>
                        <div class="body-title-2" style={{marginTop: '-2px', fontSize: '14px', fontWeight: 'normal'}}>Jumlah Pengundi Berdaftar: {resultOverlayP?.registered_voters?.toLocaleString()}</div>
                        <div class="row">
                            {
                                resultOverlayP.candidate?.map((data, idx) => {
                                    let index = 'arr4' + idx;
                                    return (
                                        <div key={index} class="col-12">
                                            <div class="parlement-wrapper">

                                                <div style={{textAlign: 'center', width: '42px', fontSize: '12px'}}>{data.coalition_abbreviation}</div>
                                                <div class="parlement-logo" style={{textAlign: 'center', width: '42px', marginRight: '12px'}}>
                                                    <img src={"/asset/image/logo/" + data.coalition_abbreviation + ".png"} style={{width: '42px'}}></img>
                                                </div>

                                                <div class="candidate-wrapper" style={{marginTop: '5px'}}>
                                                    <div class="candidate-name">{data.candidate_name}</div>
                                                    <div class="candidate-info" style={{fontSize: '12px', textAlign: 'left', marginTop: '-6px'}}>{data.p_result === 'Menang' ? data.p_result.toUpperCase() : ''}</div>
                                                </div>

                                                <div class="candidate-wrapper-detail">
                                                    <div class="candidate-total-vote">{data.p_vote.toLocaleString()}<img src="/asset/image/check.svg" class="check-image" style={data.p_result !== 'Menang' ? {display: 'none'} : {display: 'block'}}/></div>
                                                    <div style={data.p_result !== 'Menang' ? {display: 'none'} : {display: 'block', fontSize: '12px', marginLeft: '-140px'}}>{(resultOverlayP.candidate[0].p_vote - resultOverlayP.candidate[1].p_vote).toLocaleString()} MAJORITI</div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false} show={showDesicionListDun} data-backdrop="false">
                <div class="style3">
                    <div class="decision-content-title2 bg-red">
                        <span class="prev" onClick={handleBackDesicionListDun}><img src="/asset/image/chevron-left.svg" /></span>
                        <span class="text-title">KEPUTUSAN TERKINI (RASMI)</span>
                        <span class="close"></span>
                    </div>
                </div>
                <Modal.Body style={{overflow: 'auto', maxHeight: '100vh'}}>
                    <div class="col mb-2">
                        <div class="body-title-2" style={{fontSize: '20px', marginBottom: '2px'}}>JUMLAH UNDI</div>
                        <div class="body-title-2" style={{marginTop: '-8px'}}>{resultOverlayD?.dun_code} {resultOverlayD?.dun_name}</div>
                        <div class="body-title-2" style={{marginTop: '-2px', fontSize: '14px', fontWeight: 'normal'}}>Jumlah Pengundi Berdaftar: {resultOverlayD?.registered_voters?.toLocaleString()}</div>
                        <div class="row">
                            {
                                resultOverlayD.candidate?.map((data, idx) => {
                                    let index = 'arr4' + idx;
                                    return (
                                        <div key={index} class="col-12">
                                            <div class="parlement-wrapper">
                                                <div style={{textAlign: 'center', width: '42px', fontSize: '12px'}}>{data.coalition_abbreviation}</div>
                                                <div class="parlement-logo" style={{textAlign: 'center', width: '42px', marginRight: '12px'}}>
                                                    <img src={"/asset/image/logo/" + data.coalition_abbreviation + ".png"} style={{width: '42px'}}></img>
                                                </div>

                                                <div class="candidate-wrapper" style={{marginTop: '5px'}}>
                                                    <div class="candidate-name">{data.candidate_name}</div>
                                                    <div class="candidate-info" style={{fontSize: '12px', textAlign: 'left', marginTop: '-6px'}}>{data.d_result === 'Menang' ? data.d_result.toUpperCase() : ''}</div>
                                                </div>

                                                <div class="candidate-wrapper-detail">
                                                    <div class="candidate-total-vote">{data.d_vote.toLocaleString()}<img src="/asset/image/check.svg" class="check-image" style={data.d_result !== 'Menang' ? {display: 'none'} : {display: 'block'}}/></div>
                                                    <div style={data.d_result !== 'Menang' ? {display: 'none'} : {display: 'block', fontSize: '12px', marginLeft: '-140px'}}>{(resultOverlayD.candidate[0].d_vote - resultOverlayD.candidate[1].d_vote).toLocaleString()} MAJORITI</div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false} show={showFaktaMenarik} data-backdrop="false">
                <div class="close-wrapper" onClick={handleCloseFaktaMenarik}>
                    <span>X</span>
                </div>
                <Modal.Body className="no-padding">

                    <div class="fakta-menarik-mobile-wrapper">

                        <Carousel className="mobile-fact-carausel"
                            showThumbs={false}
                            swipeable={true}
                            showIndicators={false}
                            dynamicHeight={false}
                            emulateTouch={true}
                            stopOnHover={true}
                            infiniteLoop={true}
                            statusFormatter={() => { }}

                        >
                            {
                                Array.from({ length: 3 }).map((_, idx) => {
                                    let index = 'arr5' + idx;
                                    return (
                                        <div key={index} class="fakta-menarik-mobile-overlay">

                                            <div class="mt-2 modal-title-center">
                                                FAKTA MENARIK
                                            </div>


                                            <div class="title-fakta-menarik">
                                                <span>SARAWAK</span>
                                            </div>
                                            <p></p>
                                            <p>
                                                Keistimewaan Imigrasi Sarawak
                                            </p>
                                            <div class="footer-fakta-menarik"></div>
                                            <div>
                                                <center><a href="#" onClick={handleShowFaktaMenarikDetail} class="nav-link px-2 link-secondary btn-detail-senarai-selangor">KLIK UNTUK INFO LANJUT</a></center>
                                            </div>

                                        </div>
                                    )
                                })
                            }


                        </Carousel>
                    </div>
                    <div class="row">

                    </div>

                </Modal.Body>
            </Modal>


            <Modal animation={false} show={showFaktaMenarikDetail} data-backdrop="false">

                <div class="style3">

                    <div class="decision-content-title2 bg-red">
                        <span class="prev" onClick={handleCloseFaktaMenarikDetail}><img src="/asset/image/chevron-left.svg" /></span>
                        <span class="text-title">FAKTA MENARIK:SARAWAK</span>
                        <span class="close" onClick={handleCloseFaktaMenarikDetail}>X</span>
                    </div>
                </div>
                <Modal.Body style={{overflow: 'auto', maxHeight: '100vh'}}>
                    <div class="col faktta-menarik-detail mb-4">
                        <img src="/asset/image/fakta-menarik.png" />
                        <div class="body-title-2 mt-2">KEISTIMEWAAN IMIGRASI SARAWAK</div>
                        <div>Ia adalah salah satu syarat bergabungnya Sarawak dengan negeri-negeri lain yang membentuk Malaysia, masyarakat dari bumi kenyalang menikmati keistimewaan ini. Tidak perlu</div>


                    </div>
                </Modal.Body>
            </Modal>

            <Container className="navbar-main">
                <a href="https://tvstv.my" target="_blank">
                    <img src={Logo} class="img-fluid main-logo" alt=""></img>
                </a>
                <img src={MalaysiaDecides} class="img-fluid malaysia-decides-banner-mobile" alt=""></img>
                <img src="/asset/image/mobile-search.png" class="search-icon-mobile"></img>

                <div class="navbar-main-inner">
                    <ul class="main-menu main-menu-mobile justify-content-center">
                        <li >
                            <a href="#" className="nav-link px-2 link-secondary">
                                KEPUTUSAN
                            </a>
                            <ul class="full-menu">
                                <li>
                                    <Link to="/pru15" className="nav-link px-2 link-secondary">
                                        PRU 15
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/keputusan/pru14" className="nav-link px-2 link-secondary">
                                        PRU 14
                                    </Link>
                                </li>
                            </ul>

                        </li>
                        <li>
                            <Link to="#" className="nav-link px-2 link-secondary">
                                PARLIMEN
                            </Link>
                            <ul style={{left: '0px', marginTop: '0px', marginLeft: '14px', marginRight: '10px'}}>
                                {
                                    seats.map((item, idx) => {
                                        let index = 'arr6' + idx;
                                        return (
                                            <li key={index} style={{textAlign: 'center'}}>
                                                <a href="#" onClick={() => handleClickParlement(item.state_name)} rel="noopener noreferrer" className="nav-link px-2 link-secondary">
                                                    {item.state_name}
                                                </a>
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                        </li>
                        <li>
                            <Link to="#" className="nav-link px-2 link-secondary">
                                DUN
                            </Link>
                            <ul style={{left: '0px', marginTop: '0px', marginLeft: '14px', marginRight: '10px'}}>
                                {
                                    seats.map((item, idx) => {
                                        let index = 'arr7' + idx;
                                        if(item.state_name === 'W.P. LABUAN' || item.state_name === 'W.P. PUTRAJAYA' || item.state_name === 'W.P. KUALA LUMPUR'){
                                            return
                                        }
                                        else{
                                            return (
                                                <li key={index} style={election == 'pru15' ? item.state_name == 'PERLIS' ? {textAlign: "center"} : item.state_name == 'PERAK' ? {textAlign: "center"} : item.state_name == 'PAHANG' ? {textAlign: "center"} : item.state_name == 'MELAKA' ? {textAlign: "center"} : item.state_name == 'JOHOR' ? {textAlign: "center"} : item.state_name == 'SABAH' ? {textAlign: "center"} : item.state_name == 'SARAWAK' ? {textAlign: "center"} : {textAlign: "center", opacity: 0.4, pointerEvents: 'none'} : {textAlign: "center", opacity: 1, pointerEvents: 'auto'}}>
                                                    <a href="#" onClick={() => handleClickDUN(item.state_name)} rel="noopener noreferrer" className="nav-link px-2 link-secondary">
                                                        {item.state_name}
                                                    </a>
                                                </li>
                                            )
                                        }
                                    })
                                }

                            </ul>
                        </li>
                        <li>
                            <a href="#" className="nav-link px-2 link-secondary">
                                LANJUTAN
                            </a>
                            <ul class="full-menu" style={{left: '60%'}}>
                                <li>
                                    <Link to="/fakta" className="nav-link px-2 link-secondary">
                                        TAHUKAH ANDA
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/berita" className="nav-link px-2 link-secondary">
                                        BERITA PRU 15
                                    </Link>

                                </li>
                                <li>
                                    <a href="https://tvstv.my" target="_blank" rel="noopener noreferrer" className="nav-link px-2 link-secondary">
                                        TVSTV.MY
                                    </a>
                                </li>
                            </ul>

                        </li>
                    </ul>

                    <ul class="main-menu main-menu-desktop">
                        <li>
                            <Link to="/pru15" className="nav-link px-2 link-secondary">
                                KEPUTUSAN TERKINI PRU15 (RASMI)
                            </Link>
                        </li>
                        <li>
                            <Link to="/keputusan/pru14" className="nav-link px-2 link-secondary">
                                PRU14
                            </Link>
                        </li>
                        <li>
                            <a href="https://tvstv.my" target="_blank" rel="noopener noreferrer" className="nav-link px-2 link-secondary">
                                LAMAN UTAMA
                            </a>
                        </li>
                    </ul>
                    <ul class="main-menu-right">

                        <li>
                            <a href="#" onClick={handleFullScreen} id="maximize" className="social-link maximize-button">
                                <img src="/asset/image/maximize.svg" class="social-icon "></img>
                            </a>
                        </li>

                        <li>
                            <a href={process.env.REACT_APP_YOUTUBE_URL} target="_blank" className="social-link">
                                <img src="/asset/image/youtube.png" class="social-icon"></img>
                            </a>
                        </li>
                        <li>
                            <a href={process.env.REACT_APP_TWITTER_URL} target="_blank" className="social-link">
                                <img src="/asset/image/twitter.png" class="social-icon"></img>
                            </a>
                        </li>


                        <li>
                            <a href={process.env.REACT_APP_TIKTOK_URL} target="_blank" className="social-link">
                                <img src="/asset/image/tiktok.png" class="social-icon"></img>
                            </a>
                        </li>
                        <li>
                            <a href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank" className="social-link">
                                <img src="/asset/image/ig.png" class="social-icon"></img>
                            </a>
                        </li>
                        <li>
                            <a href={process.env.REACT_APP_FB_URL} target="_blank" className="social-link">
                                <img src="/asset/image/fb.png" class="social-icon"></img>
                            </a>
                        </li>
                    </ul>
                </div>

            </Container>
        </div>
    );
};

export default TopNavLive;
