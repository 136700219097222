import $ from 'jquery';
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { useParams } from "react-router-dom";
import svgPanZoom from 'svg-pan-zoom';
import { ReactComponent as MalaysiaMap } from "../components/map/malaysia-map.svg";
import Infographic from "../components/Infographic";
import StateMiddleMenuPenamaan from "../components/StateMiddleMenuPenamaan";

function Nominations() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [partyTable, setPartyTable] = useState([]);
  const [seatParliamentTable, setSeatParliamentTable] = useState([]);
  const [hotseatParliamentTable, setHotseatParliamentTable] = useState([]);
  const [seatDunTable, setSeatDunTable] = useState([]);
  const [seatType, setSeatType] = useState('PARLIMEN');
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 767;

  let { election = 'pru15',state } = useParams();
  let totalWin = 0;
  let totaldBnWin = 0;
  let totaldPhWin = 0;
  let totaldDllWin = 0;
  let totalpBnWin = 0;
  let totalpPhWin = 0;
  let totalpGpsWin = 0;
  let totalpDllWin = 0;
  let BnName;
  let PhName;
  let GpsName;
  let DllName; 
  let bgBnColor;
  let bgPhColor;
  let bgGpsColor;
  let bgDllColor;
  
  function showPopup(element) {
    $('#hotseatpop1').hide();
    $('#hotseatpop2').hide();
    $('#' + element).show();
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'instant'
    });
  }

  function closePopup(element) {
    $('#' + element).hide();
    $('#hotseatpop1').show();
    $('#hotseatpop2').show();
  }

  function getPartyParliamentTable() {
    fetch(`${process.env.REACT_APP_API_URL}/api/microsite/overall/tally_parliament?election=${election}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setPartyTable(result.tally);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  function getSeatTable() {
    fetch(`${process.env.REACT_APP_API_URL}/api/microsite/overall/seat_table?election=${election}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setSeatDunTable(result.DUN);
          setSeatParliamentTable(result.Parliament);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  function getHotseatParliamentTable() {
    fetch(`${process.env.REACT_APP_API_URL}/api/microsite/hotseat/result_parliament?election=${election}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setHotseatParliamentTable(result.Parliament_Results);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }  

  useEffect(() => {

    $(document).on('mousemove', '#map-wrapper .small-box', function (e) {
      let overlay_id;

      if(seatType === 'PARLIMEN')
        overlay_id = 'P_' + (e.target).parentNode.id.toUpperCase();
      else
        overlay_id = 'D_' + (e.target).parentNode.id.toUpperCase();
      
      if((e.target).parentNode.id === 'SARAWAK' || (e.target).parentNode.id === 'SABAH' || (e.target).parentNode.id === 'WPLabuan') {
        $('#' + overlay_id).css(
          { top: e.clientY - 70, left: e.clientX - 425, }
        );
      }else{
        $('#' + overlay_id).css(
          { top: e.clientY - 70, left: e.clientX + 30, }
        );
      }
    })

    $(document).on('mouseleave', '#map-wrapper .small-box', function (e) {
      $('.map-hover-info-wrapper').hide();
    })    

    $(document).on('mouseover', '#map-wrapper .small-box', function (e) {
      let overlay_id;
      $('.map-hover-info-wrapper').hide();
      
      if(seatType === 'PARLIMEN')
        overlay_id = 'P_' + (e.target).parentNode.id.toUpperCase();
      else
        overlay_id = 'D_' + (e.target).parentNode.id.toUpperCase();

      $('#' + overlay_id).show();
    })

    if(seatType === 'PARLIMEN'){
      seatParliamentTable?.map((data, idx) => {
        let index = 'seatparliamenttable_' + idx;
        let state_map = data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','');
        bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
        bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
        bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
        bgDllColor = 'silver';

        const party_list = [
          {id: 'BN', win: data.stats[0].BN ? data.stats[0].BN.win : 0},
          {id: 'PH', win: data.stats[0].PH ? data.stats[0].PH.win : 0},
          {id: 'GPS', win: data.stats[0].GPS ? data.stats[0].GPS.win : 0},
          {id: 'DLL', win: data.stats[0].DLL ? data.stats[0].DLL.win : 0},
        ];

        let winner = party_list.sort((a, b) => (a.win < b.win) ? 1 : -1)[0].id;

        //if(winner === 'BN')
        //  $('#' + state_map).css('fill', bgBnColor);

        //if(winner === 'PH')
        //  $('#' + state_map).css('fill', bgPhColor);

        //if(winner === 'GPS')
        //  $('#' + state_map).css('fill', bgGpsColor);
          
        //if(winner === 'DLL')
        //  $('#' + state_map).css('fill', bgDllColor);
        $('#WPKUALALUMPUR').css('fill', '#fff');
        $('#WPPUTRAJAYA').css('fill', '#fff');
        $('#WPLABUAN').css('fill', '#fff');
        $('#SARAWAK').css('fill', '#fff');
        $('#SABAH').css('fill', '#fff');
        $('#PULAUPINANG').css('fill', '#fff');
        $('#KEDAH').css('fill', '#fff');
        $('#MELAKA').css('fill', '#fff');
        $('#JOHOR').css('fill', '#fff');
        $('#NEGERISEMBILAN').css('fill', '#fff');
        $('#TERENGGANU').css('fill', '#fff');
        $('#KELANTAN').css('fill', '#fff');
        $('#SELANGOR').css('fill', '#fff');
      });
    }else{
      seatDunTable.map((data, idx) => {
        let index = 'seatduntable_' + idx;
        let state_map = data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','');
        bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
        bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
        bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
        bgDllColor = 'silver';
  
        const party_list = [
          {id: 'BN', win: data.stats[0].BN ? data.stats[0].BN.win : 0},
          {id: 'PH', win: data.stats[0].PH ? data.stats[0].PH.win : 0},
          {id: 'GPS', win: data.stats[0].GPS ? data.stats[0].GPS.win : 0},
          {id: 'DLL', win: data.stats[0].DLL ? data.stats[0].DLL.win : 0},
        ];
  
        let winner = party_list.sort((a, b) => (a.win < b.win) ? 1 : -1)[0].id;
  
        //if(winner === 'BN')
        //  $('#' + state_map).css('fill', bgBnColor);
  
        //if(winner === 'PH')
        //  $('#' + state_map).css('fill', bgPhColor);
  
        //if(winner === 'GPS')
        //  $('#' + state_map).css('fill', bgGpsColor);
          
        //if(winner === 'DLL')
        //  $('#' + state_map).css('fill', bgDllColor); 

        $('#WPKUALALUMPUR').css('fill', '#000');
        $('#WPPUTRAJAYA').css('fill', '#000');
        $('#WPLABUAN').css('fill', '#000');
        $('#SARAWAK').css('fill', '#000');
        $('#SABAH').css('fill', '#000');
        $('#PULAUPINANG').css('fill', '#000');
        $('#KEDAH').css('fill', '#000');
        $('#MELAKA').css('fill', '#000');
        $('#JOHOR').css('fill', '#000');
        $('#NEGERISEMBILAN').css('fill', '#000');
        $('#TERENGGANU').css('fill', '#000');
        $('#KELANTAN').css('fill', '#000');
        $('#SELANGOR').css('fill', '#000');
      }); 
    }   
  }, [seatType])


  useEffect(() => {
    setPartyTable([]);
    setSeatParliamentTable([]);
    setSeatDunTable([]);
    getPartyParliamentTable();
    getSeatTable();
    getHotseatParliamentTable();

    let zoomMap = svgPanZoom('#svgMalaysia', {
      zoomEnabled: false,
      controlIconsEnabled: false,
      fit: true,
      center: true,
    });

    document.querySelector('.nav-map-zoom-out').addEventListener('click', function () {
      zoomMap.zoomOut();
    })
    document.querySelector('.nav-map-zoom-in').addEventListener('click', function () {
      zoomMap.zoomIn();
    })
    document.querySelector('.nav-map-zoom-reset').addEventListener('click', function () {
      zoomMap.reset();
    })

    document.title = "Keputusan " + election.toUpperCase(); 
  }, [election, state])

  useEffect(() => {
    document.title = "Keputusan " + election.toUpperCase(); 
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <>
      <Row></Row>
      <StateMiddleMenuPenamaan election={election} />
      <Row></Row>

      <div class="decision-content-wrapper">
        <div class=" decision-table-content">
          <Table bordered hover>
            <thead>
              <tr>
                <th></th>
                <th style={width < breakpoint ? {textAlign: "left", fontSize: '12px'} : {textAlign: "left"}}>PARTI</th>
                <th style={width < breakpoint ? {textAlign: "center", width: '100px', fontSize: '12px'} : {textAlign: "center"}}>BERTANDING</th>
              </tr>
            </thead>
            <tbody>

              {

                partyTable.map((data, idx) => {
                  totalWin = totalWin + data.run;
                  let index = 'partytable_' + idx;
                  return (
                    <tr key={index}>
                      <td style={width < breakpoint ? {width: '35px', backgroundColor: data.coalition_color} : {width: '150px', backgroundColor: data.coalition_color}}></td>
                      <td style={width < breakpoint ? {textAlign: "left", fontSize: '13px'} : {textAlign: "left"}}>{width < breakpoint ? data.coalition_abbreviation.toUpperCase() : data.coalition_name.toUpperCase()}</td>
                      <td style={width < breakpoint ? {textAlign: "center", fontSize: '14px'} : {textAlign: "center"}}>{data.run}</td>
                    </tr>
                  )
                })
              }

            </tbody>
          </Table>
        </div>
      </div>

      <div class="decision-content-wrapper hide-mobile style4 desicion-bg-1 mt-4">
        <div class={(seatType == 'PARLIMEN' ? 'active with-carrtet-left' : '') + ' decision-content-title title-bg-navy2 dec-hover'} onClick={() => { setSeatType('PARLIMEN'); }}>
          <span class="text-title">PETA KERUSI PARLIMEN</span>
        </div>
        <div class={(seatType == 'DUN' ? 'active with-carrtet-left' : '') + ' decision-content-title title-bg-navy2 dec-hover'} onClick={() => { setSeatType('DUN'); }}>
          <span class="text-title">PETA KERUSI DEWAN UNDANGAN NEGERI</span>
        </div>

        <div class=" decision-table-content decision-bg-1">

          <div id="map-wrapper">
            <MalaysiaMap />
          </div>
          <div class="map-navigation-controll-wrapper">
            <img class="map-navigation-controll nav-map-zoom-in" src="/asset/image/map-zoom-in.svg" />
            <img class="map-navigation-controll nav-map-zoom-out" src="/asset/image/map-zoom-out.svg" />
            <img class="map-navigation-controll nav-map-zoom-reset" src="/asset/image/map-zoom-reset.svg" />
          </div>

          {
            seatParliamentTable?.map((data, idx) => {
              let index = 'seatparliamenttable_' + idx;
              let state_map = data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','');
              bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
              bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
              bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
              bgDllColor = 'silver';
      
              const party_list = [
                {id: 'BN', win: data.stats[0].BN ? data.stats[0].BN.win : 0},
                {id: 'PH', win: data.stats[0].PH ? data.stats[0].PH.win : 0},
                {id: 'GPS', win: data.stats[0].GPS ? data.stats[0].GPS.win : 0},
                {id: 'DLL', win: data.stats[0].DLL ? data.stats[0].DLL.win : 0},
              ];
      
              let winner = party_list.sort((a, b) => (a.win < b.win) ? 1 : -1)[0].id;
              console.log('winner: ' + winner);
              
              //if(winner === 'BN')
              //  $('#' + state_map).css('fill', bgBnColor);
      
              //if(winner === 'PH')
              //  $('#' + state_map).css('fill', bgPhColor);
      
              //if(winner === 'GPS')
              //  $('#' + state_map).css('fill', bgGpsColor);
                
              //if(winner === 'DLL')
              //  $('#' + state_map).css('fill', bgDllColor);

              if(election === 'pru15') {
                BnName = 'BN';
                PhName = 'PH';
                GpsName = 'GPS';
                DllName = 'DLL'; 
              }else{
                BnName = 'BN';
                PhName = 'PKR';
                GpsName = 'GPS';
                DllName = 'DLL'; 
              }

              return (
              <div key={index} id={'P_' + data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','')} class="map-hover-info-wrapper" style={{display: 'none'}}>
                <div class="row">
                  <div class="map-tip-head-title">
                    <strong>{data.state_name}</strong>
                    <div class="seat-info">{data.stats[0].total_seat} KERUSI PARLIMEN</div>
                  </div>
                  <div class="flag-wrapper">
                    <img src={'/asset/image/flags/' + data.state_name.toLowerCase().replace('.','').replace('.','').replace(' ','').replace(' ','') + '.svg'} />
                  </div>
                  <div class="parlement-seat-wrapper">
                    <div class="row">
                    <div class="col-6 mb-3">
                      <div class="party-initial-sym" style={{paddingLeft: '9px', color: '#fff', backgroundColor: '#282a74'}}>BN</div> <div class="seat-info-sub">{data.stats[0].BN ? data.stats[0].BN.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '9px', color: '#fff', backgroundColor: bgPhColor}}>PH</div> <div class="seat-info-sub">{data.stats[0].PH ? data.stats[0].PH.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '9px', color: '#fff', backgroundColor: '#0e314e'}}>PN</div> <div class="seat-info-sub">{data.stats[0].PN ? data.stats[0].PN.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '5px', color: '#fff', backgroundColor: '#116a8d'}}>PEJ</div> <div class="seat-info-sub">{data.stats[0].GTA ? data.stats[0].GTA.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3" style={data.state_name === 'SARAWAK' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '4px', color: '#000', backgroundColor: '#ffffff'}}>GPS</div> <div class="seat-info-sub">{data.stats[0].GPS ? data.stats[0].GPS.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3" style={data.state_name === 'SABAH' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '4px', color: '#000', backgroundColor: '#91b8e3'}}>GRS</div> <div class="seat-info-sub">{data.stats[0].GRS ? data.stats[0].GRS.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3" style={data.state_name != 'SARAWAK' && data.state_name != 'SABAH' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '5px', backgroundColor: bgDllColor}}>DLL</div> <div class="seat-info-sub">{data.stats[0].DLL1 ? data.stats[0].DLL1.win : 0} CALON</div>
                      </div>                         
                      <div class="col-6 mb-3" style={data.state_name === 'SARAWAK' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '5px', backgroundColor: bgDllColor}}>DLL</div> <div class="seat-info-sub">{data.stats[0].DLL2 ? data.stats[0].DLL2.win : 0} CALON</div>
                      </div> 
                      <div class="col-6 mb-3" style={data.state_name === 'SABAH' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '5px', backgroundColor: bgDllColor}}>DLL</div> <div class="seat-info-sub">{data.stats[0].DLL3 ? data.stats[0].DLL3.win : 0} CALON</div>
                      </div>                                       
                    </div>
                  </div>
                </div>
              </div>)
            })
          }

          {
            seatDunTable.map((data, idx) => {
              let index = 'seatduntable_' + idx;
              bgBnColor = data.stats[0].BN ? data.stats[0].BN.color : bgBnColor;
              bgPhColor = data.stats[0].PH ? data.stats[0].PH.color : bgPhColor;
              bgGpsColor = data.stats[0].GPS ? data.stats[0].GPS.color : bgGpsColor;
              bgDllColor = 'silver';

              if(election === 'pru15') {
                BnName = 'BN';
                PhName = 'PH';
                GpsName = 'GPS';
                DllName = 'DLL'; 
              }else{
                BnName = 'BN';
                PhName = 'PKR';
                GpsName = 'GPS';
                DllName = 'DLL'; 
              }

              if(data.state_name == 'SARAWAK'){
                return
              }
              else if(data.state_name == 'SABAH'){
                return
              }
              else if(data.state_name == 'KEDAH'){
                return
              }  
              else if(data.state_name == 'PULAU PINANG'){
                return
              }
              else if(data.state_name == 'SELANGOR'){
                return
              }
              else if(data.state_name == 'NEGERI SEMBILAN'){
                return
              } 
              else if(data.state_name == 'TERENGGANU'){
                return
              } 
              else if(data.state_name == 'KELANTAN'){
                return
              }                             
              else if(data.state_name == 'MELAKA'){
                return
              }
              else if(data.state_name == 'JOHOR'){
                return
              }                                                                            
              else
              return (
              <div key={index} id={'D_' + data.state_name.replace('.','').replace('.','').replace(' ','').replace(' ','')}  class="map-hover-info-wrapper" style={{display: 'none'}}>
                <div class="row">
                  <div class="map-tip-head-title">
                    <strong>{data.state_name}</strong>
                    <div class="seat-info">{data.stats[0].total_seat} KERUSI DUN</div>
                  </div>
                  <div class="flag-wrapper">
                    <img src={'/asset/image/flags/' + data.state_name.toLowerCase().replace('.','').replace('.','').replace(' ','').replace(' ','') + '.svg'} />
                  </div>
                  <div class="parlement-seat-wrapper">
                    <div class="row">
                    <div class="col-6 mb-3">
                      <div class="party-initial-sym" style={{paddingLeft: '9px', color: '#fff', backgroundColor: '#282a74'}}>BN</div> <div class="seat-info-sub">{data.stats[0].BN ? data.stats[0].BN.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '9px', color: '#fff', backgroundColor: bgPhColor}}>PH</div> <div class="seat-info-sub">{data.stats[0].PH ? data.stats[0].PH.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '9px', color: '#fff', backgroundColor: '#0e314e'}}>PN</div> <div class="seat-info-sub">{data.stats[0].PN ? data.stats[0].PN.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="party-initial-sym" style={{paddingLeft: '5px', color: '#fff', backgroundColor: '#116a8d'}}>PEJ</div> <div class="seat-info-sub">{data.stats[0].GTA ? data.stats[0].GTA.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3" style={data.state_name === 'SARAWAK' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '4px', color: '#000', backgroundColor: '#ffffff'}}>GPS</div> <div class="seat-info-sub">{data.stats[0].GPS ? data.stats[0].GPS.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3" style={data.state_name === 'SABAH' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '4px', color: '#000', backgroundColor: '#91b8e3'}}>GRS</div> <div class="seat-info-sub">{data.stats[0].GRS ? data.stats[0].GRS.win : 0} CALON</div>
                      </div>
                      <div class="col-6 mb-3" style={data.state_name != 'SARAWAK' && data.state_name != 'SABAH' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '5px', backgroundColor: bgDllColor}}>DLL</div> <div class="seat-info-sub">{data.stats[0].DLL1 ? data.stats[0].DLL1.win : 0} CALON</div>
                      </div>                         
                      <div class="col-6 mb-3" style={data.state_name === 'SARAWAK' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '5px', backgroundColor: bgDllColor}}>DLL</div> <div class="seat-info-sub">{data.stats[0].DLL2 ? data.stats[0].DLL2.win : 0} CALON</div>
                      </div> 
                      <div class="col-6 mb-3" style={data.state_name === 'SABAH' && election === 'pru15' ? {} : {display: 'none'}}>
                        <div class="party-initial-sym" style={{paddingLeft: '5px', backgroundColor: bgDllColor}}>DLL</div> <div class="seat-info-sub">{data.stats[0].DLL3 ? data.stats[0].DLL3.win : 0} CALON</div>
                      </div>                                  
                    </div>
                  </div>
                </div>
              </div> )
            })
          }         
          
        </div>
      </div>
      <div class="decision-content-wrapper style2 mt-4">
        <div id='hotseatpop1' class="decision-content-title">
          <span class="text-title">KERUSI TUMPUAN</span>
        </div>
        <div id='hotseatpop2' class="row mb-3" style={{paddingLeft: '10px'}}>
          {
            hotseatParliamentTable.map((data, idx) => {
                let el_id = 'hotseat_popup_' + data.parliament_code.replace('P.00','P').replace('P.0','P').replace('P.','P');
                return (
                    <div key={idx} class="col-md-4">
                        <a href='javascript:' data-id={data.parliament_id} onClick={() => showPopup(el_id)} className="nav-link px-2 link-secondary" style={{color:'black', fontSize: '16px', fontWeight: 'bold'}}>{data.parliament_code} {data.parliament_name}</a>
                    </div>
                )
            })
          }
        </div>
        {
          hotseatParliamentTable.map((data, idx) => {
          let index = 'hotseat_' + idx;
          let el_id = 'hotseat_popup_' + data.parliament_code.replace('P.00','P').replace('P.0','P').replace('P.','P');
          let majority = data.candidate[0]?.p_vote - data.candidate[1]?.p_vote;
          return (
              <div key={index} id={el_id} class="decision-content-wrapper style3 decision2-list-overlay" style={{display: 'none'}}>
                  <div class="decision-content-title">
                      <span class="prev" onClick={() => closePopup(el_id)}><img src="/asset/image/chevron-left.svg" /></span>
                      <span class="text-title" style={{width: '100%', left: '0px'}}>KEPUTUSAN RASMI</span>
                      <span class="close"></span>
                  </div>

                  <div class=" decision-table-content">

                      <div class="row">

                          <div class="col px-5">


                              <div class="body-title-2 mt-2"></div>
                              <div class="body-title-2" style={{marginTop: '-8px'}}>{data.parliament_code} {data.parliament_name}</div>

                              <div class="row">
                                  {
                                      data.candidate.map((data_result, idx_result) => {
                                          let index = 'cpp_ ' + idx_result;
                                          return (
                                              <div key={index} class="col-12">
                                                  <div class="parlement-wrapper">

                                                      <div class="parlement-logo">
                                                          <img src={"/asset/image/candidates/" + data_result.candidate_image} />
                                                      </div>
                                                      <div class="parlement-logo">
                                                          <div className="text-center" style={{fontWeight: 'bold', backgroundColor: '#b5b5b5'}}>{(data_result.coalition_abbreviation)}</div>
                                                          <img src={"/asset/image/logo/" + data_result.coalition_abbreviation + ".png"} />
                                                      </div>

                                                      <div class="candidate-wrapper">

                                                          <div class="candidate-name">{data_result.candidate_name}</div>
                                                      </div>

                                                  </div>
                                              </div>
                                          )
                                      })
                                  }

                              </div>
                              <div class="body-title-2 mt-5" style={{marginTop: '-8px', fontSize: '22px'}}>Jumlah Pengundi Berdaftar: {data?.registered_voters?.toLocaleString()}</div>
                          </div>
                      </div>
                  </div>
              </div>)
              }
          )
        }         
      </div>     
    </>
  );
}

export default Nominations;
