import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as P_PerlisMap } from "../components/map/p_perlis.svg"
import { ReactComponent as P_KedahMap } from "../components/map/p_kedah.svg"
import { ReactComponent as P_PulauPinangMap } from "../components/map/p_pulaupinang.svg"
import { ReactComponent as P_PerakMap } from "../components/map/p_perak.svg"
import { ReactComponent as P_SelangorMap } from "../components/map/p_selangor.svg"
import { ReactComponent as P_NegeriSembilanMap } from "../components/map/p_negerisembilan.svg"
import { ReactComponent as P_MelakaMap } from "../components/map/p_melaka.svg"
import { ReactComponent as P_JohorMap } from "../components/map/p_johor.svg"
import { ReactComponent as P_PahangMap } from "../components/map/p_pahang.svg"
import { ReactComponent as P_TerengganuMap } from "../components/map/p_terengganu.svg"
import { ReactComponent as P_KelantanMap } from "../components/map/p_kelantan.svg"
import { ReactComponent as P_SabahMap } from "../components/map/p_sabah.svg"
import { ReactComponent as P_SarawakMap } from "../components/map/p_sarawak.svg"
import { ReactComponent as P_WPKualaLumpurMap } from "../components/map/p_wpkualalumpur.svg"
import { ReactComponent as P_WPPutrajayaMap } from "../components/map/p_wpputrajaya.svg"
import { ReactComponent as P_WPLabuanMap } from "../components/map/p_wplabuan.svg"
import { ReactComponent as D_PerlisMap } from "../components/map/d_perlis.svg"
import { ReactComponent as D_KedahMap } from "../components/map/d_kedah.svg"
import { ReactComponent as D_PulauPinangMap } from "../components/map/d_pulaupinang.svg"
import { ReactComponent as D_PerakMap } from "../components/map/d_perak.svg"
import { ReactComponent as D_SelangorMap } from "../components/map/d_selangor.svg"
import { ReactComponent as D_NegeriSembilanMap } from "../components/map/d_negerisembilan.svg"
import { ReactComponent as D_PahangMap } from "../components/map/d_pahang.svg"
import { ReactComponent as D_TerengganuMap } from "../components/map/d_terengganu.svg"
import { ReactComponent as D_KelantanMap } from "../components/map/d_kelantan.svg"
import { ReactComponent as D_SabahMap } from "../components/map/d_sabah.svg"
import { ReactComponent as D_SarawakMap } from "../components/map/d_sarawak.svg"
import { ReactComponent as D_MelakaMap } from "../components/map/d_melaka.svg"
import { ReactComponent as D_JohorMap } from "../components/map/d_johor.svg"
import svgPanZoom from 'svg-pan-zoom'
import $ from "jquery";

function InfoMap() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [resultStateParliament, setStateResultParliament] = useState([]);
    const [resultStateDun, setStateResultDun] = useState([]);   
    const [seatType, setSeatType] = useState('PARLIMEN');

    let { election = 'pru14',state } = useParams();
    let zoomMap = null;
    let bgBnColor;
    let bgPhColor;
    let bgGpsColor;
    let bgDllColor;  
    let BnName;
    let PhName;
    let GpsName;
    let DllName; 

    function showPopup(element) {
        $('#' + element).show();
    }

    function closePopup(element) {
        $('#' + element).hide();
    }

    function truncate(str) {
        return str.length > 34 ? str.substring(0, 32) + "..." : str;
    }

    function truncateAbbr(str) {
        return str.length > 3 ? str.substring(0, 3): str;
    }

    function getResultParliament() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/state/result_parliament?election=${election}&state=${state}`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setStateResultParliament(result.Parliament_Results);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
    
    function getResultDun() {
        fetch(`${process.env.REACT_APP_API_URL}/api/microsite/state/result_dun?election=${election}&state=${state}`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setStateResultDun(result.DUN_Results);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }      

    function StateMap() {
        if(seatType === 'PARLIMEN'){
            setTimeout(function () {
                resultStateParliament?.map((data, idx) => {
                    let area_map = data.parliament_code.replace('P.00','P').replace('P.0','P').replace('P.','P');
                });
             }, 1000);

            if (state === 'kelantan') {
                return (
                    <P_KelantanMap />
                )
            } else if (state === 'kedah') {
                return (
                    <P_KedahMap />
                )
            } else if (state === 'perak') {
                return (
                    <P_PerakMap />
                )
            } else if (state === 'negeri sembilan') {
                return (
                    <P_NegeriSembilanMap />
                )
            } else if (state === 'pahang') {
                return (
                    <P_PahangMap />
                )
            } else if (state === 'perlis') {
                return (
                    <P_PerlisMap />
                )
            } else if (state === 'pulau pinang') {
                return (
                    <P_PulauPinangMap />
                )
            } else if (state === 'selangor') {
                return (
                    <P_SelangorMap />
                )
            } else if (state === 'terengganu') {
                return (
                    <P_TerengganuMap />
                )
            } else if (state === 'johor') {
                return (
                    <P_JohorMap />
                )
            }else if (state === 'melaka') {
                return (
                    <P_MelakaMap />
                )
            }else if (state === 'sabah') {
                return (
                    <P_SabahMap />
                )
            }else if (state === 'sarawak') {
                return (
                    <P_SarawakMap />
                )
            }else if (state === 'w.p. kuala lumpur') {
                return (
                    <P_WPKualaLumpurMap />
                )
            }else if (state === 'w.p. putrajaya') {
                return (
                    <P_WPPutrajayaMap />
                )
            }else if (state === 'w.p. labuan') {
                return (
                    <P_WPLabuanMap />
                )
            }else{
                return;
            };
        }else if(seatType === 'DUN'){
            setTimeout(function () {
                resultStateDun?.map((data, idx) => {
                    let area_map = data.dun_code.replace('N.0','N').replace('N.','N');
                    if(state === 'sabah' || state === 'sarawak' || state === 'melaka' || state === 'johor')
                        $('#' + area_map).css('fill', data.candidate ? data.candidate[0]?.coalition_color : '#fff');
                });
             }, 1000);

            if (state === 'kelantan') {
                return (
                    <D_KelantanMap />
                )
            } else if (state === 'kedah') {
                return (
                    <D_KedahMap />
                )
            } else if (state === 'perak') {
                return (
                    <D_PerakMap />
                )
            } else if (state === 'negeri sembilan') {
                return (
                    <D_NegeriSembilanMap />
                )
            } else if (state === 'pahang') {
                return (
                    <D_PahangMap />
                )
            } else if (state === 'perlis') {
                return (
                    <D_PerlisMap />
                )
            } else if (state === 'pulau pinang') {
                return (
                    <D_PulauPinangMap />
                )
            } else if (state === 'selangor') {
                return (
                    <D_SelangorMap />
                )
            } else if (state === 'terengganu') {
                return (
                    <D_TerengganuMap />
                )
            } else if (state === 'johor') {
                return (
                    <D_JohorMap />
                )
            }else if (state === 'melaka') {
                return (
                    <D_MelakaMap />
                )
            }else if (state === 'sabah') {
                return (
                    <D_SabahMap />
                )
            }else if (state === 'sarawak') {
                return (
                    <D_SarawakMap />
                )                
            }else if (state === 'w.p. kuala lumpur') {
                return (
                    <P_WPKualaLumpurMap />
                )
            }else if (state === 'w.p. putrajaya') {
                return (
                    <P_WPPutrajayaMap />
                )
            }else if (state === 'w.p. labuan') {
                return (
                    <P_WPLabuanMap />
                )                
            }else{
                return;
            };
        }
    }

    function initMap() {

        setTimeout(function () {
            zoomMap = svgPanZoom('#map-wrapper svg', {
                zoomEnabled: false,
                controlIconsEnabled: false,
                fit: false,
                center: false,
            });
            
            $('.nav-map-zoom-out').unbind('click')
            $('.nav-map-zoom-in').unbind('click')
            $('.nav-map-zoom-reset').unbind('click')


            document.querySelector('.nav-map-zoom-out').addEventListener('click', function () {
                zoomMap.zoomOut();
            })
            document.querySelector('.nav-map-zoom-in').addEventListener('click', function () {
                zoomMap.zoomIn();
            })
            document.querySelector('.nav-map-zoom-reset').addEventListener('click', function () {
                zoomMap.reset();
            })
        }, 1000);
    }

    function move_up() {
        document.getElementById('scroll_list').scrollTop = 0;
      }
    
      function move_down() {
        document.getElementById('scroll_list').scrollTop = 1000;
      }

    useEffect(() => {

        if(seatType === 'PARLIMEN'){
            $('#DUN_List').hide();
            $('#Parliament_List').show();
        }else if(seatType === 'DUN'){
            $('#Parliament_List').hide();
            $('#DUN_List').show();
        }

        $(document).on('mousemove', '#map-wrapper .small-box', function (e) {
          let map_element = 'overlay_' + (e.target).parentNode.id.toUpperCase();
            
          $('#' + map_element).css(
            { top: e.clientY - 70, left: e.clientX - 60, }
          );
        })
    
        $(document).on('mouseleave', '#map-wrapper .small-box', function (e) {
            $('.map-hover-info-wrapper').hide();
        })    
    
        $(document).on('mouseover', '#map-wrapper .small-box', function (e) {
          $('.map-hover-info-wrapper').hide();
          let overlay_id = 'overlay_' + (e.target).parentNode.id.toUpperCase();

          $('#' + overlay_id).show();
        })
    
    }, [seatType])

    useEffect(() => {
        setStateResultParliament([]);
        setStateResultDun([]);
        setSeatType('PARLIMEN');
        getResultParliament();
        getResultDun();

        if (zoomMap) {
            zoomMap.destroy();
        }

        initMap();
    },[election, state])

    return (
        <>
            <div class={(state.includes('w.p.') || state.includes('pulau pinang') || state.includes('kedah') || state.includes('kelantan') || state.includes('terengganu') || state.includes('negeri sembilan') || state.includes('selangor') ? "style5 " : "style4 ") + "decision-content-wrapper desicion-bg-1 candidate-decision-map-wrapper"}>
                <div class="decision-content-title title-bg-navy inherit-bg d-flex justify-content-center">
                    <span class="text-title">CALON - CALON &amp; KEPUTUSAN</span>
                </div>

                <div class={(seatType == 'PARLIMEN' ? 'active with-carrtet-left ' : '') + 'decision-content-title title-bg-navy2 dec-hover'} onClick={() => { setSeatType('PARLIMEN'); }}>
                    <span class="text-title">KERUSI PARLIMEN</span>
                </div>
                <div class={(seatType == 'DUN' ? 'active with-carrtet-left ' : '') + 'decision-content-title title-bg-navy2 dec-hover'} style={state.includes('w.p.') || state.includes('pulau pinang') || state.includes('kedah') || state.includes('kelantan') || state.includes('terengganu') || state.includes('negeri sembilan') || state.includes('selangor') ? {display: "none"} : {display: "block"}} onClick={() => { setSeatType('DUN'); }}>
                    <span class="text-title">KERUSI DEWAN UNDANGAN NEGERI</span>
                </div>

                <div class=" decision-table-content decision-bg-1">

                    <div id="map-wrapper" class="map-svg-wrapper">
                        <StateMap key={state} />
                    </div>

                    <div class="map-navigation-controll-wrapper nav-decision-candidate">
                        <img class="map-navigation-controll nav-map-zoom-in" src="/asset/image/map-zoom-in.svg" />
                        <img class="map-navigation-controll nav-map-zoom-out" src="/asset/image/map-zoom-out.svg" />
                        <img class="map-navigation-controll nav-map-zoom-reset" src="/asset/image/map-zoom-reset.svg" />
                    </div>

                    <div class="map-city-list-wrapper">
                        <div class="map-scroll-top" style={seatType === 'PARLIMEN' ? resultStateParliament.length > 25 ? {visibility: 'visible'} : {visibility: 'hidden'} : resultStateDun.length > 25 ? {visibility: 'visible'} : {visibility: 'hidden'}} onClick={move_up}>
                            <img src="/asset/image/chevron-right.svg" style={{paddingTop: '3px', paddingLeft: '0px'}}/>
                        </div>
                        <div class="map-scroll-bottom" style={seatType === 'PARLIMEN' ? resultStateParliament.length > 25 ? {visibility: 'visible'} : {visibility: 'hidden'} : resultStateDun.length > 25 ? {visibility: 'visible'} : {visibility: 'hidden'}} onClick={move_down}>
                            <img src="/asset/image/chevron-right.svg" style={{paddingTop: '3px', paddingLeft: '4px'}}/>
                        </div>
                        <div id='scroll_list' class="map-scroll-city-wrap">

                            <div id="Parliament_List" class="map-city-body" style={{display: 'none'}}>
                                {
                                    resultStateParliament.map((data, idx) => {
                                        let el_id = state.replace('.','').replace('.','').replace(' ','').replace(' ','') + '_popup_' + data.parliament_code.replace('P.00','P').replace('P.0','P').replace('P.','P');
                                        return (
                                            <div key={idx}>
                                                <a data-id={data.parliament_id} onClick={() => showPopup(el_id)}>{data.parliament_code} <span style={{fontWeight: 400}}>{data.parliament_name}</span></a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div id="DUN_List" class="map-city-body" style={{display: 'none'}}>
                                {
                                    resultStateDun?.map((data, idx) => {
                                        let el_id = state.replace('.','').replace('.','').replace(' ','').replace(' ','') + '_popup_' + data.dun_code.replace('N.0','N').replace('N.','N');
                                        return (
                                            <div key={idx}>
                                                <a data-id={data.dun_id} onClick={() => showPopup(el_id)}>{data.dun_code} <span style={{fontWeight: 400}}>{data.dun_name}</span></a>
                                            </div>
                                        )
                                    })
                                }

                            </div>                            
                        </div>
                    </div>

                    <div>
                    {
                        resultStateParliament.map((data, idx) => {
                        let index = 'op_' + idx;
                        return (
                            <div key={index} id={'overlay_' + data.parliament_code.replace('P.00','P').replace('P.0','P').replace('P.','P')} class="map-hover-info-wrapper" style={{height: (data.candidate.length * 41) + 105, width: 560, display: 'none'}}>
                                <div class="row">
                                    <div class="map-parlement-title" style={{textAlign: 'center', marginBottom: '0px'}}>{data.parliament_code} {data.parliament_name}</div>
                                    <div class="map-parlement-title" style={{textAlign: 'center', fontSize: '14px'}}>Jumlah Pengundi Berdaftar: {data.registered_voters?.toLocaleString()}</div>
                                    {
                                        data.candidate.map((data_result, idx_result) => {
                                            return (
                                            <div key={idx_result} class="row">
                                                <div class="map-parlement-candidate-stat col-10" style={{paddingLeft: '20px', fontWeight:'bold'}}><img src={"/asset/image/logo/" + data_result.coalition_abbreviation + ".png"} style={{height: '32px',paddingRight: '10px',paddingBottom: '2px'}}></img>{data_result.candidate_name} ({data_result.coalition_abbreviation})</div>
                                            </div>
                                            )}
                                        )
                                    }
                                </div>    
                            </div>)
                            }
                        )
                    }
                    {
                        resultStateDun?.map((data, idx) => {
                        let index = 'od_' + idx;
                        return (
                            <div key={index} id={'overlay_' + data.dun_code.replace('N.0','N').replace('N.','N')} class="map-hover-info-wrapper" style={{height: (data.candidate.length * 41) + 105, width: 560, display: 'none'}}>
                                <div class="row">
                                    <div class="map-parlement-title" style={{textAlign: 'center', marginBottom: '0px'}}>{data.dun_code} {data.dun_name}</div>
                                    <div class="map-parlement-title" style={{textAlign: 'center', fontSize: '14px'}}>Jumlah Pengundi Berdaftar: {data?.registered_voters?.toLocaleString()}</div>
                                    {
                                        data.candidate.map((data_result, idx_result) => {
                                            return (
                                            <div key={idx_result} class="row">
                                                <div class="map-parlement-candidate-stat col-10" style={{paddingLeft: '20px', fontWeight:'bold'}}><img src={"/asset/image/logo/" + data_result.coalition_abbreviation + ".png"} style={{height: '32px',paddingRight: '10px',paddingBottom: '2px'}}></img>{data_result.candidate_name} ({data_result.coalition_abbreviation})</div>
                                                <div class="map-parlement-candidate-stat col-2" style={election == 'pru15' ? state?.toUpperCase() == 'PERLIS' ? {display: "none"} : state?.toUpperCase() == 'PERAK' ? {display: "none"} : state?.toUpperCase() == 'PAHANG' ? {display: "none"} : {fontWeight:'bold'} : {fontWeight:'bold'}}>{data_result.d_vote ? data_result.d_vote?.toLocaleString() : 0}</div>
                                            </div>
                                            )}
                                        )
                                    }
                                </div>    
                            </div>)
                            }
                        )
                    }
                    </div>
                </div>

                {
                    resultStateParliament.map((data, idx) => {
                    let index = 'pp_' + idx;
                    let el_id = state.replace('.','').replace('.','').replace(' ','').replace(' ','') + '_popup_' + data.parliament_code.replace('P.00','P').replace('P.0','P').replace('P.','P');
                    let majority = data.candidate[0]?.p_vote - data.candidate[1]?.p_vote;
                    return (
                        <div key={index} id={el_id} class="decision-content-wrapper style3 decision-list-overlay" style={{maxHeight: (data.candidate.length * 150) + 120, display: 'none'}}>
                            <div class="decision-content-title">
                                <span class="text-title" style={{position: 'absolute', width: '100%', left: '0px'}}>KEPUTUSAN RASMI</span>
                                <span class="close" onClick={() => closePopup(el_id)}>X</span>
                            </div>
        
                            <div class=" decision-table-content">
        
                                <div class="row">
        
                                    <div class="col px-5">
        
        
                                        <div class="body-title-2 mt-2"></div>
                                        <div class="body-title-2" style={{marginTop: '-8px'}}>{data.parliament_code} {data.parliament_name}</div>
        
                                        <div class="row">
                                            {
                                                data.candidate.map((data_result, idx_result) => {
                                                    let index = 'cpp_ ' + idx_result;
                                                    return (
                                                        <div key={index} class="col-12">
                                                            <div class="parlement-wrapper">
        
                                                                <div class="parlement-logo">
                                                                    <img src={"/asset/image/candidates/" + data_result.candidate_image} />
                                                                </div>
                                                                <div class="parlement-logo">
                                                                    <div className="text-center" style={{fontWeight: 'bold', backgroundColor: '#b5b5b5'}}>{(data_result.coalition_abbreviation)}</div>
                                                                    <img src={"/asset/image/logo/" + data_result.coalition_abbreviation + ".png"} />
                                                                </div>
        
                                                                <div class="candidate-wrapper">
        
                                                                    <div class="candidate-name">{data_result.candidate_name}</div>
                                                                </div>
        
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
        
                                        </div>
                                        <div class="body-title-2 mt-5" style={{marginTop: '-8px', fontSize: '22px'}}>Jumlah Pengundi Berdaftar: {data?.registered_voters?.toLocaleString()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        }
                    )
                }

                {
                    resultStateDun?.map((data, idx) => {
                    let index = 'pd_' + idx;
                    let el_id = state.replace('.','').replace('.','').replace(' ','').replace(' ','') + '_popup_' + data.dun_code.replace('N.0','N').replace('N.','N');
                    let majority = data.candidate[0]?.d_vote - data.candidate[1]?.d_vote;
                    return (
                        <div key={index} id={el_id} class="decision-content-wrapper style3 decision-list-overlay" style={{maxHeight: (data.candidate.length * 150) + 120, display: 'none'}}>
                            <div class="decision-content-title">
                                <span class="text-title" style={{position: 'absolute', width: '100%', left: '0px'}}>KEPUTUSAN RASMI</span>
                                <span class="close" onClick={() => closePopup(el_id)}>X</span>
                            </div>
        
                            <div class=" decision-table-content">
        
                                <div class="row">
        
                                    <div class="col px-5">

                                        <div class="body-title-2 mt-2"></div>
                                        <div class="body-title-2" style={election == 'pru15' ? state?.toUpperCase() == 'PERLIS' ? {display: "none"} : state?.toUpperCase() == 'PERAK' ? {display: "none"} : state?.toUpperCase() == 'PAHANG' ? {display: "none"} : {} : {}}>JUMLAH UNDI</div>
                                        <div class="body-title-2" style={{marginTop: '-8px'}}>{data.dun_code} {data.dun_name}</div>
        
                                        <div class="row">
                                            {
                                                data.candidate.map((data_result, idx_result) => {
                                                    return (
                                                        <div class="col-12">
                                                            <div class="parlement-wrapper">

                                                                <div class="parlement-logo" style={election == 'pru15' ? state?.toUpperCase() == 'SABAH' ? {display: "none"} : state?.toUpperCase() == 'MELAKA' ? {display: "none"} : state?.toUpperCase() == 'JOHOR' ? {display: "none"} : {} : {}}>
                                                                    <img src={"/asset/image/candidates/" + data_result.candidate_image} />
                                                                </div>
                                                                <div class="parlement-logo">
                                                                    <div className="text-center" style={{fontWeight: 'bold', backgroundColor: '#b5b5b5'}}>{(data_result.coalition_abbreviation)}</div>
                                                                    <img src={"/asset/image/logo/" + data_result.coalition_abbreviation + ".png"} />
                                                                </div>
        
                                                                <div class="candidate-wrapper">
        
                                                                    <div class="candidate-name">{data_result.candidate_name}</div>
                                                                    <div class="candidate-info" style={election == 'pru15' ? state?.toUpperCase() == 'PERLIS' ? {display: "none"} : state?.toUpperCase() == 'PERAK' ? {display: "none"} : state?.toUpperCase() == 'PAHANG' ? {display: "none"} : {} : {}}>{data_result.d_result === 'Menang' ? data_result.d_result.toUpperCase() : ''}</div>
                                                                </div>
        
                                                                <div class="candidate-wrapper-detail" style={election == 'pru15' ? state?.toUpperCase() == 'PERLIS' ? {display: "none"} : state?.toUpperCase() == 'PERAK' ? {display: "none"} : state?.toUpperCase() == 'PAHANG' ? {display: "none"} : {} : {}}>
                                                                    <div class="candidate-total-vote">{data_result.d_vote?.toLocaleString()} <span class='title-majority' style={data_result.d_result !== 'Menang' ? {display: 'none'} : {display: 'inline'}}>{majority?.toLocaleString()} MAJORITI</span><img src="/asset/image/check.svg" class="check-image" style={data_result.d_result !== 'Menang' ? {display: 'none'} : {display: 'inline'}}/></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
        
                                        </div>
                                        <div class="body-title-2 mt-5" style={{marginTop: '-8px', fontSize: '22px'}}>Jumlah Pengundi Berdaftar: {data?.registered_voters?.toLocaleString()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        }
                    )
                }                
            </div>
        </>
    );
}

export default InfoMap;