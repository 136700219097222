import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom";
import MalaysiaDecides from './image/malaysia-decides.png';
import Pru15 from "./Pru15Carousel";
import Facts from "./FactsCarousel";
import './style/App.css';
import './style/Mobile.css';
import TopNavLive from "./TopNavLive";
import TopTicker from "./TopTicker";
import { useLocation } from 'react-router-dom';

function Menu() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [middleMenus, setItems] = useState([]);
    let [isNews, setIsNews] = useState(false);
    let [isFacts, setIsFacts] = useState(false);
    let [newsPage, setNewsPage] = useState(false);
    const url = window.location.pathname.split("/");
    const { id } = useParams();
    const location = useLocation();

    useEffect(() => {
        setIsNews(false)
        if (typeof url[1] != 'undefined') {
            if (url[1] === 'berita') {
                setIsNews(true)
            }
        }

        setIsFacts(false)
        if (typeof url[1] != 'undefined') {
            if (url[1] === 'fakta') {
                setIsFacts(true)
            }
        }
        
    }, [location])

    return (
        <div>
            <TopTicker />
            <TopNavLive />
            <Container>
                <div class="row" style={{width: '100%', margin: '0px'}}>
                    <img src={MalaysiaDecides} class="img-fluid malaysia-decides-banner" alt="" style={{paddingLeft: '0px'}}></img>
                    
                    <Pru15></Pru15>

                    <Facts></Facts>

                </div>
            </Container>
        </div >
    );
}

export default Menu;
